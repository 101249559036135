import { Api } from "lib/axios/Api";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import { Notice } from "model/Notice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { commonSave, DateToYmd } from "assets/js/common";

export default function NoticeView() {
  const navigate = useNavigate();
  const params = useParams();
  const { idx } = params;
  const [notice, setNotice] = useState<Partial<Notice>>({ status: 0 });
  const [isDate, setIsDate] = useState(false);
  const isView = parseInt(idx as string) ? true : false;

  useEffect(() => {
    if (isView) {
      Api.get(`/notices/${idx}`).then((response) => {
        setNotice(response.data);
        setIsDate(
          response.data.dateStart !== null &&
            response.data.dateStart !== undefined
        );
      });
    }
  }, [idx, isView]);

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    commonSave({ send });
  };

  const send: any = () => {
    if (isView) return Api.put(`/notices/${idx}`, notice);
    else
      return Api.post(`/notices`, notice).then(() => navigate("/help/notices"));
  };

  function updateModel(fieldsToUpdate: Partial<Notice>) {
    if (notice)
      setNotice((model) => {
        return { ...model, ...fieldsToUpdate };
      });
  }
  return (
    <MyContainer title={isView ? "공지사항 상세" : "공지사항 등록"}>
      <Form onSubmit={(e) => submitHandler(e)}>
        <Row>
          <Col md="10">
            {isView && notice && (
              <>
                <Row className="mb-3" md="3">
                  <Form.Group as={Col} controlId="idx" className="mb-3">
                    <Form.Label>No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={notice.idx || ""}
                      readOnly
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} controlId="email" className="mb-3">
                    <Form.Label>등록일</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      readOnly
                      key={notice.idx}
                      defaultValue={notice?.admin?.email}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="email" className="mb-3">
                    <Form.Label>등록자</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      readOnly
                      key={notice.idx}
                      defaultValue={notice?.admin?.name}
                    />
                  </Form.Group>
                </Row>
              </>
            )}

            <Row className="mb-3">
              <Form.Group
                as={Col}
                controlId="ccceName"
                className="mb-3"
                md="auto"
              >
                <Form.Label>게시 기간</Form.Label>
                <Col className="mb-3" md="auto">
                  <Form.Check
                    inline
                    label="없음"
                    name="is_date"
                    type="radio"
                    value="0"
                    id="is_date_0"
                    checked={!isDate}
                    onChange={() => {
                      setIsDate(false);
                      updateModel({ dateStart: undefined, dateEnd: undefined });
                    }}
                  />
                  <Form.Check
                    inline
                    label="있음"
                    name="is_date"
                    type="radio"
                    value="1"
                    id="is_date_1"
                    checked={isDate}
                    onChange={() => setIsDate(true)}
                  />
                </Col>
              </Form.Group>
              {isDate && (
                <>
                  <Form.Group
                    as={Col}
                    controlId="ccceName"
                    className="mb-3"
                    md="auto"
                  >
                    <Form.Label>시작일</Form.Label>
                    <ReactDatePicker
                      className="form-control"
                      selected={
                        notice?.dateStart ? new Date(notice?.dateStart) : null
                      }
                      onChange={(e) => updateModel({ dateStart: DateToYmd(e) })}
                      dateFormat="Y-MM-dd"
                      locale={ko}
                      name={`dateStart`}
                      minDate={new Date()}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="ccmaName"
                    className="mb-3"
                    md="auto"
                  >
                    <Form.Label>종료일</Form.Label>
                    <ReactDatePicker
                      className="form-control"
                      selected={
                        notice?.dateEnd ? new Date(notice?.dateEnd) : null
                      }
                      onChange={(e) => updateModel({ dateEnd: DateToYmd(e) })}
                      dateFormat="Y-MM-dd"
                      locale={ko}
                      name={`dateStart`}
                      minDate={new Date()}
                      required
                    />
                  </Form.Group>
                </>
              )}
            </Row>

            <Row className="mb-3" md="1" xs="1">
              <Form.Label>공지사항 종류</Form.Label>
              <Col className="mb-3">
                <Form.Check
                  inline
                  label="필수"
                  name="type"
                  type="radio"
                  value="1"
                  id="type_1"
                  defaultChecked={true}
                />
                <Form.Check
                  inline
                  label="일반"
                  name="type"
                  type="radio"
                  value="0"
                  id="type_0"
                  defaultChecked={false}
                />
              </Col>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="ccceName" className="mb-3">
                <Form.Label>제목</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  defaultValue={notice?.title}
                  onChange={(e) => updateModel({ title: e.target.value })}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="a" className="mb-3">
                <CKEditor
                  editor={ClassicEditor}
                  data={notice.text ?? ""}
                  onChange={(e: any, editor: any) => {
                    updateModel({ text: editor.getData() });
                  }}
                  config={{
                    language: "ko",
                    ckfinder: {
                      uploadUrl:
                        process.env.REACT_APP_API_URL + "/images?uri=notice",
                    },
                    image: {
                      onclick: (e: any) => {},
                    },
                  }}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3" md="1" xs="1">
              <Col className="mb-3 d-flex justify-content-end">
                <Form.Check
                  inline
                  label="공개"
                  name="visibled"
                  type="radio"
                  value="1"
                  id="visibled_1"
                  checked={notice.status === 1 ?? false}
                  onChange={(e) => updateModel({ status: 1 })}
                />
                <Form.Check
                  inline
                  label="비공개"
                  name="visibled"
                  type="radio"
                  value="0"
                  id="visibled_0"
                  checked={notice.status === 0 ?? true}
                  onChange={(e) => updateModel({ status: 0 })}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Col className="d-flex justify-content-end">
          <Button
            variant="danger"
            className="ms-3"
            onClick={() => navigate(-1)}
          >
            취소
          </Button>
          <Button type="submit" variant="primary" className="ms-3">
            저장
          </Button>
        </Col>
      </Form>
    </MyContainer>
  );
}
