import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Row, Table } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { Link, useNavigate, useParams } from "react-router-dom";
import KakaoIcon from "assets/images/sns/kakaotalk_sharing_btn_small.png";
import NaverIcon from "assets/images/sns/naver_btn_smal.png";
import AppleIcon from "assets/images/sns/kakaotalk_sharing_btn_small.png";
import MyContainer from "components/layouts/MyContainer";
import { User, UserStatusLog } from "model/User";

function UserView() {
  const navigate = useNavigate();
  const params = useParams();
  const { idx } = params;
  const [user, setUser] = useState<User>();
  const [userStatusLogs, setUserStatusLogs] = useState<UserStatusLog[]>([]);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  const reload = () => {
    Api.get(`/users/${idx}`).then((response) => setUser(response.data));
    Api.get(`/user_status_logs/${idx}`).then((response) => {
      setUserStatusLogs(response.data);
    });
  };

  const convertStatus = (status: number) => {
    switch (status) {
      case 0:
        return "일반";
      case 1:
        return "경고";
      case 2:
        return "블랙리스트";
    }
  };

  const getUserIcon = (): string | undefined => {
    if (user) {
      switch (user.snsType) {
        case "KAKAO":
          return KakaoIcon;
        case "NAVER":
          return NaverIcon;
        case "APPLE":
          return AppleIcon;
      }
    }
  };

  if (user)
    return (
      <MyContainer title="회원 상세보기">
        <Row>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="idx" md="auto">
                <Form.Label>No</Form.Label>
                <Form.Control type="text" readOnly value={user.idx} />
              </Form.Group>
            </Row>
            <Row className="mb-3" xs="1" md="3">
              <Form.Group as={Col} controlId="a" md="auto">
                <Form.Label>이메일</Form.Label>
                <Form.Control type="email" readOnly value={user.email} />
              </Form.Group>

              <Form.Group as={Col} controlId="b" md="auto">
                <Form.Label>닉네임</Form.Label>
                <Form.Control type="text" readOnly value={user.nickName} />
              </Form.Group>

              <Form.Group as={Col} controlId="c" md="auto">
                <Form.Label>성별</Form.Label>
                <Form.Select disabled value={user.gender}>
                  <option value={0}>비공개</option>
                  <option value={1}>남성</option>
                  <option value={2}>여성</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="idx">
                <Form.Label className="me-3">가입경로: </Form.Label>
                {getUserIcon() && (
                  <Image src={getUserIcon()} style={{ maxWidth: "2rem" }} />
                )}
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="idx">
                <Form.Label className="me-3">리뷰 수: </Form.Label>
                <Link
                  to={`/service/reviews?inputName=userEmail&userEmail=${user.email}`}
                >
                  {user.reviewCnt}
                </Link>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="idx" md="auto">
                <Form.Label className="me-3">경고 누적</Form.Label>
                <Form.Control type="text" readOnly value={user.warningCnt} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="idx" xs="auto" className="mb-3">
                <Row>
                  <Col md="auto">
                    <Form.Label className="me-3">상태</Form.Label>
                  </Col>
                  <Col md="auto">
                    <Form.Control
                      disabled
                      value={convertStatus(user.status)}
                      style={{ width: "7rem" }}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            {user.blackMemo && (
              <Row className="mb-3">
                <Form.Group as={Col} controlId="idx" md="10" lg="6">
                  <Form.Label className="me-3">블랙리스트 사유</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={user.blackMemo ?? "-"}
                  />
                </Form.Group>
              </Row>
            )}

            <Row className="my-5">
              <Col>
                <h5>경고/블랙리스트 내역</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>날짜</th>
                      <th>내역</th>
                      <th>사유</th>
                      <th>리뷰</th>
                      <th>취소일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userStatusLogs &&
                      userStatusLogs.map((log) => (
                        <tr
                          key={log.idx}
                          className={
                            log.canceledAt && "text-decoration-line-through"
                          }
                        >
                          <td>{log.createdAt}</td>
                          <td>{convertStatus(log.status)}</td>
                          <td>{log.reason}</td>
                          <td>
                            {log.reviewIdx && (
                              <Link
                                to={`/service/reviews/${log.reviewIdx}`}
                                target="_blank"
                              >
                                <Button size="sm">확인</Button>
                              </Link>
                            )}
                          </td>
                          <td>{log.canceledAt}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="idx" md="auto">
                <Form.Label className="me-3">마지막 로그인 일자</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={user.lastLoginAt ?? "-"}
                />
              </Form.Group>
            </Row>
            <Col className="d-flex justify-content-end">
              <Button
                variant="danger"
                className="ms-3"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
            </Col>
          </Form>
        </Row>
      </MyContainer>
    );
  else return <></>;
}

export default UserView;
