import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { commonSave, validatePassword } from "assets/js/common";
import { Api } from "lib/axios/Api";

const AdminPasswordChange = () => {
  const params = useParams();
  const { idx } = params;
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [invalidByPassword, setInvalidByPassword] = useState(false);
  const [invalidByConfirm, setInvalidByConfirm] = useState(false);
  const pwdRef = useRef() as React.RefObject<HTMLInputElement>;
  const pwdConfirmRef = useRef() as React.RefObject<HTMLInputElement>;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (!invalidByPassword)
      commonSave({
        send: () => {
          return Api.put(`/admins/pwd_change/${idx}`, {
            password: pwdRef.current?.value,
          }).then(() => {
            navigate(-1);
          });
        },
      });
    return false;
  };

  const checkPassword = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let password = pwdRef.current?.value;
    let confirmPwd = pwdConfirmRef.current?.value;
    setInvalidByConfirm(password !== confirmPwd);
    if (password) {
      setInvalidByPassword(!validatePassword(password));
    }
  };
  return (
    <Container className="h-100 d-flex align-items-center justify-content-center">
      <Form
        className="align-middle "
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row>
          <Form.Group as={Col} controlId="password" className="mb-3 col-auto">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              type="password"
              isInvalid={invalidByPassword}
              ref={pwdRef}
              onChange={(e) => checkPassword(e)}
              required
            />
            <Form.Control.Feedback type="invalid">
              영문 대/소문자, 숫자 특문 8~15 문자
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            controlId="password_confirm"
            className="mb-3 col-auto"
          >
            <Form.Label>비밀번호 확인</Form.Label>
            <Form.Control
              type="password"
              isInvalid={invalidByConfirm}
              ref={pwdConfirmRef}
              onChange={(e) => checkPassword(e)}
              required
            />
            <Form.Control.Feedback type="invalid">
              비밀번호가 일치하지 않습니다.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Col className="d-flex justify-content-center mb-3">
          <Button
            variant="danger"
            className="mx-3"
            onClick={() => navigate(-1)}
          >
            취소
          </Button>
          <Button type="submit" variant="primary">
            변경
          </Button>
        </Col>
      </Form>
    </Container>
  );
};

export default AdminPasswordChange;
