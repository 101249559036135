import { appErrorMsg, commonSwalConfirm } from "assets/js/common";
import { Api } from "lib/axios/Api";
import { QuestionType, QuestionTypeLevel } from "model/Question";
import { Badge, Button, CloseButton, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";

interface LevelComponentProps {
  questionType: QuestionType;
  questionTypeLevels: QuestionTypeLevel[];
  setQuestionTypeLevels: React.Dispatch<
    React.SetStateAction<QuestionTypeLevel[]>
  >;
}

const QuestionTypeLevelComponet = ({
  questionType,
  questionTypeLevels,
  setQuestionTypeLevels,
}: LevelComponentProps) => {
  const addLevelClickHandler = () => {
    Swal.fire({
      title: "LEVEL",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = result.value.trim();
        if (!value) {
          return appErrorMsg({ msg: "LEVEL이 입력되지 않았습니다." });
        } else if (questionTypeLevels.indexOf(value) >= 0) {
          return appErrorMsg({ msg: "이미 동일한 LEVEL이 있습니다." });
        }

        const confirm = await commonSwalConfirm({
          title: "추가하시겠습니까?",
          confirmButtonText: "추가",
        });

        if (confirm.isConfirmed) {
          const newLevel: Partial<QuestionTypeLevel> = {
            text: value,
            questionTypeIdx: questionType.idx,
          };
          Api.post("question_type_level", newLevel).then((response) => {
            setQuestionTypeLevels((levels) => [...levels, response.data]);
          });
        }
      }
    });
  };

  const deleteLevelClickHandler = async (
    questionTypeLevel: QuestionTypeLevel
  ) => {
    const result = await commonSwalConfirm({
      title: "삭제하시겠습니까?",
      confirmButtonText: "삭제",
    });
    if (result.isConfirmed) {
      Api.delete(`question_type_level/${questionTypeLevel.idx}`).then(
        (respone) => {
          setQuestionTypeLevels((levels) =>
            levels.filter((l) => questionTypeLevel !== l)
          );
        }
      );
    }
  };

  return (
    <Row className="mb-3 flex-column" xs="3">
      <Col>
        <Row>
          <Col className="col-auto">LEVEL</Col>
          <Col className="col-auto">
            <Row>
              {questionTypeLevels.map((level, i) => (
                <Col className="col-auto" key={i}>
                  <Badge
                    className="text-primary border border-primary"
                    bg="white"
                  >
                    <span className="me-2">{level.text}</span>
                    <CloseButton
                      style={{
                        width: ".2rem",
                        height: ".2rem",
                        display: "inline-flex",
                      }}
                      onClick={() => deleteLevelClickHandler(level)}
                    />
                  </Badge>
                </Col>
              ))}
            </Row>
          </Col>
          <Col>
            <Button size="sm" onClick={addLevelClickHandler}>
              추가
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default QuestionTypeLevelComponet;
