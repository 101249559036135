import { configureStore } from "@reduxjs/toolkit";
import authReducer from "features/authSlice";

const authMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);
  if (action.type?.startsWith("auth/login")) {
  }
  return result;
};

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
