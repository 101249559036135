import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyPagination from "components/MyPagination";
import MyContainer from "components/layouts/MyContainer";
import RegistBtn from "components/display/RegistBtn";
import ExcelBtn from "components/display/ExcelBtn";
import { CCCE_NAMES, CCMA_NAMES, SIDOS } from "constants/CONSTANTS";
import { CulturalAsset } from "model/CulturalAsset";
import SelectFilter from "components/display/SelectFilter";
import InputFilter from "components/display/InputFilter";
import { commonSearch } from "assets/js/common";
import FilterResetBtn from "components/display/FilterResetBtn";

const CulturalAssets = () => {
  const [rows, setRows] = useState<CulturalAsset[]>([]);
  const [data, setData] = useState<any>();
  const search = commonSearch("/cultural_assets", { setData, setRows });
  useEffect(() => commonSearch("/cultural_assets", { setData, setRows })(), []);

  return (
    <MyContainer title="문화재 관리">
      <>
        <div>
          <Row className="mb-3">
            <SelectFilter search={search} name="ccbaCtcdNm">
              <option value="">지역</option>
              {SIDOS.map((sido, i) => (
                <option key={i} value={sido}>
                  {sido}
                </option>
              ))}
            </SelectFilter>

            <SelectFilter search={search} name="ccceName">
              <option value="">시대</option>
              {CCCE_NAMES.map((ccce, i) => (
                <option key={i} value={ccce}>
                  {ccce}
                </option>
              ))}
            </SelectFilter>

            <SelectFilter search={search} name="ccmaName">
              <option value="">종목</option>
              {CCMA_NAMES.map((ccma, i) => (
                <option key={i} value={ccma}>
                  {ccma}
                </option>
              ))}
            </SelectFilter>

            <SelectFilter search={search} name="status">
              <option value="">상태</option>
              <option value="1">활성화</option>
              <option value="0">비활성화</option>
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
          <InputFilter search={search}>
            <option value="ccbaMnm1">문화재 명</option>
            <option value="ccbaAsno">고유번호</option>
          </InputFilter>

          <Row>
            <Col className="d-flex align-items-end justify-content-end mb-3">
              <ExcelBtn />
              <RegistBtn />
            </Col>
          </Row>
        </div>

        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>고유번호</th>
              <th>문화재 명</th>
              <th>시대</th>
              <th>종목</th>
              <th>기출문제 수</th>
              <th>지역</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td key={row.idx}>
                  <Link to={`./${row.idx}`}>
                    {data.number * data.size + index + 1}
                  </Link>
                </td>
                <td>{row.ccbaAsno}</td>
                <td className="text-truncate" style={{ maxWidth: "10rem" }}>
                  {row.ccbaMnm1}
                </td>
                <td className="text-truncate" style={{ maxWidth: "10rem" }}>
                  {row.ccceName ?? "-"}
                </td>
                <td>{row.ccmaName ?? "-"}</td>
                <td>{0}</td>
                <td>{row.ccbaCtcdNm}</td>
                <td>
                  {Boolean(row.status) ? (
                    <span className="text-success">활성화</span>
                  ) : (
                    <span className="text-danger">비활성화</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </>
    </MyContainer>
  );
};

export default CulturalAssets;
