import { commonSearch } from "assets/js/common";
import MyPagination from "components/MyPagination";
import FilterResetBtn from "components/display/FilterResetBtn";
import RegistBtn from "components/display/RegistBtn";
import SelectFilter from "components/display/SelectFilter";
import MyContainer from "components/layouts/MyContainer";
import { Notice } from "model/Notice";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Notices() {
  const [data, setData] = useState<any>();
  const [rows, setRows] = useState<Notice[]>([]);

  const search = commonSearch("/notices", { setData, setRows });
  useEffect(() => commonSearch("/notices", { setData, setRows })(), []);

  const convertDate = (notice: Notice) => {
    let start = notice.dateStart ? notice.dateStart : "-";
    let end = notice.dateEnd ? notice.dateEnd : "-";
    if (notice.dateStart && notice.dateEnd) return `${start} ~ ${end}`;
    return "-";
  };

  return (
    <MyContainer title="공지사항">
      <div>
        <div>
          <Row className="mb-3">
            <Col md="auto">
              <SelectFilter search={search} name="status">
                <option value="">상태</option>
                <option value="0">비공개</option>
                <option value="1">공개</option>
              </SelectFilter>
            </Col>
            <FilterResetBtn search={search} />
          </Row>
          <Row>
            <Col className="d-flex align-items-end justify-content-end mb-3">
              <RegistBtn />
            </Col>
          </Row>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>제목</th>
              <th>등록자ID</th>
              <th>등록일</th>
              <th>게시기간</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td key={row.idx}>
                  <Link to={`./${row.idx}`}>
                    {data?.number * data.size + index + 1}
                  </Link>
                </td>
                <td>{row.title}</td>
                <td>{row.admin?.name}</td>
                <td>{row.createdAt}</td>
                <td>{convertDate(row)}</td>
                <td>{row.status === 0 ? "비공개" : "공개"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
}
