import { Button, Col } from "react-bootstrap"
import QueryString from 'query-string';
import { deleteQueryString } from "assets/js/common";

const FilterResetBtn = ({exceptions}: {exceptions?: string[], search?: Function}) => {
  let qs = QueryString.parse(window.location.search);

  const filterDeleteClickHandler = () => {
    Object.entries(qs).forEach(([key]) => {
      if(!exceptions || exceptions.indexOf(key) < 0) {
        deleteQueryString(key);
      }
    });
    window.location.reload();
  }
  return (
    <Col md="auto">
      <Button variant="outline-dark" onClick={filterDeleteClickHandler}>Reset</Button>
    </Col>
  )
}

export default FilterResetBtn;