import { Api } from "lib/axios/Api";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import { Faq } from "model/types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { appSuccessMsg } from "assets/js/common";

export default function FaqView() {
  const navigate = useNavigate();
  const params = useParams();
  const { idx } = params;
  const [faq, setFaq] = useState<Partial<Faq>>({ status: 0 });
  const isView = parseInt(idx as string) ? true : false;

  useEffect(() => {
    if (isView)
      Api.get(`/faqs/${idx}`).then((response) => setFaq(response.data));
  }, [idx, isView]);

  const submitHandler = async () => {
    isView ? await Api.put(`/faqs/${idx}`, faq) : await Api.post(`/faqs/`, faq);
    appSuccessMsg({ msg: "저장되었습니다." });
    if (!isView) navigate(-1);
  };

  return (
    <MyContainer title={isView ? "자주 묻는 질문 상세" : "자주 묻는 질문 등록"}>
      <Form>
        {isView && (
          <>
            <Row className="mb-3" md="3">
              <Form.Group as={Col} controlId="idx" className="mb-3">
                <Form.Label>No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  readOnly
                  defaultValue={faq.idx}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="email" className="mb-3">
                <Form.Label>등록일</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  readOnly
                  defaultValue={faq.createdAt}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="email" className="mb-3">
                <Form.Label>등록자</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  readOnly
                  defaultValue={faq.admin?.name}
                />
              </Form.Group>
            </Row>
          </>
        )}

        <Row md="2">
          <Form.Group as={Col} controlId="ccceName" className="mb-3">
            <Form.Label>제목</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={faq.title ?? ""}
              onChange={(e) => setFaq({ ...faq, title: e.target.value })}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="a" className="mb-3">
            <CKEditor
              editor={ClassicEditor}
              data={faq.text ?? ""}
              onChange={(e: any, editor: any) => {
                setFaq((faq) => {
                  return { ...faq, text: editor.getData() };
                });
              }}
              config={{
                language: "ko",
                ckfinder: {
                  uploadUrl:
                    process.env.REACT_APP_API_URL + "/images?uri=notice",
                },
                image: {
                  onclick: (e: any) => {},
                },
              }}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3" md="1" xs="1">
          <Col className="mb-3 d-flex justify-content-end">
            <Form.Check
              inline
              label="비공개"
              name="visibled"
              type="radio"
              value="0"
              id="visibled_0"
              checked={faq.status === 0}
              onChange={() => setFaq({ ...faq, status: 0 })}
            />
            <Form.Check
              inline
              label="공개"
              name="visibled"
              type="radio"
              value="1"
              id="visibled_1"
              checked={faq.status === 1}
              onChange={() => setFaq({ ...faq, status: 1 })}
            />
          </Col>
        </Row>
        <Col className="d-flex justify-content-end">
          <Button
            variant="danger"
            className="ms-3"
            onClick={() => navigate(-1)}
          >
            취소
          </Button>
          <Button variant="primary" className="ms-3" onClick={submitHandler}>
            저장
          </Button>
        </Col>
      </Form>
    </MyContainer>
  );
}
