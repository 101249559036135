import Button from 'react-bootstrap/Button';
import { Col, Container, Form } from 'react-bootstrap';
import { Api } from 'lib/axios/Api';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function FindId() {
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      onSearchSubmit();
    }

    setValidated(true);
  }

  const onSearchSubmit = async () => {
    Api.post('/find_id', { email })
      .then((response) => {
        setId(response.data?.id);
      })
      .catch((err) => {
        if(err.response.status === 404) {
          alert('일치하는 회원을 찾을 수 없습니다.');
        } else {
          alert('알 수 없는 에러가 발생했습니다.');
          console.log(err.response.status);
        }
      });
  }
  let content: JSX.Element;
  if(id) {
    content = (
      <>
        <Col className='text-center mb-3'>
          {id}
        </Col>
      </>
    )
  } else {
    content = (
      <>
        <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Enter email" 
            defaultValue={email} 
            onChange={(e: any) => setEmail(e.target.value)} 
            required />
          <Form.Control.Feedback type="invalid">
            올바른 이메일을 입력해주세요.
          </Form.Control.Feedback>
        </Form.Group>

        <Col className="d-flex justify-content-center mb-3">
          <Button type="submit" variant="primary">Login</Button>
        </Col>
      </>
    )
  }
  return (
    <Container className="h-100 d-flex align-items-center justify-content-center">
      <Form className="align-middle border rounded p-5 bg-muted" noValidate validated={validated} onSubmit={handleSubmit}>
        {content}

        <Col className="d-flex justify-content-center mb-1">
          <Link to="/find_pw">비밀번호 찾기</Link>
        </Col>
        <Col className="d-flex justify-content-center">
          <Link to="/">로그인</Link>
        </Col>
      </Form>
    </Container>
  );
}
