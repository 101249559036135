import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Api } from 'lib/axios/Api';
import MyContainer from 'components/layouts/MyContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { Term, TermColumn } from 'model/types';
import { Admin } from 'model/Admin';
import { ko } from "date-fns/esm/locale";
import ReactDatePicker from 'react-datepicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commonSave, DateToYmd } from 'assets/js/common';
import { toast } from 'react-toastify';

export default function TermColumnView() {
  const navigate = useNavigate();
  const params = useParams();
  const { idx, parentIdx } = params;
  const [column, setColumn] = useState<Partial<TermColumn>>({
    termIdx: parseInt(parentIdx as string),
    status: 0,
  });
  const [admin, setAdmin] = useState<Partial<Admin>>({});
  const [term, setTerm] = useState<Partial<Term>>({});
  const isView = parseInt(idx as string) ? true : false;

  useEffect(() => {
    if(isView) Api.get(`/term_columns/${idx}`).then((response) => setColumn(response.data));
  }, [idx, isView]);

  useEffect(() => {
    if(column.adminIdx) Api.get(`/admins/${column.adminIdx}`).then((response) => setAdmin(response.data));
    if(column.termIdx) Api.get(`/terms/${column.termIdx}`).then((response) => setTerm(response.data));
  }, [column]);

  function updateModel(fieldsToUpdate: Partial<TermColumn>) {
    if (column) setColumn((model) => { return { ...model, ...fieldsToUpdate }})
  }
  
  const submitHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.checkValidity()) {
      if(!column.text) {
        toast.error("약관이 입력되지 않았습니다.", {autoClose: 1000, pauseOnFocusLoss: false});
      } else {
        commonSave({send});
      }
    }
    return false;
  }
  
  const send: any = () => {
    if(isView) return Api.put(`/term_columns/${idx}`, column);
    else return Api.post(`/term_columns`, column).then(() => navigate(`/help/terms/${parentIdx}`))
  }
  
  return (
    <MyContainer title="약관 상세">
      <>
        <Form onSubmit={submitHandler}>
          {isView && <>
            <Row className="mb-3" md="auto">
              <Form.Group as={Col} controlId="idx" className="mb-3">
                <Form.Label>No</Form.Label>
                <Form.Control type="text" placeholder="" readOnly defaultValue={column.idx} />
              </Form.Group>

              <Form.Group as={Col} controlId="created_at" className="mb-3">
                <Form.Label>등록일</Form.Label>
                <Form.Control type="text" placeholder="" readOnly defaultValue={column.createdAt} />
              </Form.Group>

              <Form.Group as={Col} controlId="name" className="mb-3">
                <Form.Label>등록자</Form.Label>
                <Form.Control type="text" placeholder="" readOnly defaultValue={admin?.name} />
              </Form.Group>
            </Row>
          </>
          }
          <Row md="auto">
            <Form.Group as={Col} controlId="startDate" className="mb-3">
              <Form.Label>공개일</Form.Label>
                <ReactDatePicker
                  className="form-control"
                  selected={column.startDate ? new Date(column.startDate) : null}
                  onChange={(e) => updateModel({ startDate: DateToYmd(e) })}
                  dateFormat="Y-MM-dd"
                  locale={ko}
                  name={`startDate`}
                  required
                />
            </Form.Group>

            <Form.Group as={Col} controlId="title" className="mb-3">
              <Form.Label>버전</Form.Label>
              <Form.Control type="text" placeholder="" value={column.version ?? ''} onChange={(e) => updateModel({ version: e.target.value })} maxLength={10} required/>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="title" className="mb-3">
              <Form.Label>제목</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={term.title} readOnly/>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="title" className="mb-3">
              <Form.Label className='fs-7 text-danger'>※ 이미지 업로드는 지원하지 않고 있습니다.</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={column.text ?? ""}
                onChange={(e: any, editor: any) => {
                  updateModel({text: editor.getData()})
                }}
                config={{
                  language: "ko",
                }}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="title" className="mb-3" md="auto">
              <Form.Check 
                type="switch"
                id="custom-switch"
                checked={column.status === 1}
                label="활성"
                onChange={(e) => updateModel({status: e.target.checked ? 1 : 0})}
              />
            </Form.Group>
          </Row>

          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="danger" className='ms-3' onClick={() => navigate(-1)}>취소</Button>
              <Button variant="primary" className='ms-3' type='submit'>저장</Button>
            </Col>
          </Row>
        </Form>
      </>
    </MyContainer>
  );
}
