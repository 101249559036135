import { Api } from "lib/axios/Api";
import { useState } from "react";
import { Button, Form, InputGroup, Row, Table } from "react-bootstrap";
import MyContainer from "components/layouts/MyContainer";
import { CulXml } from "model/CulXml";
import { appSuccessMsg } from "assets/js/common";
import { useNavigate } from "react-router-dom";

function CulResigst() {
  const [name, setName] = useState("");
  const [list, setList] = useState<CulXml[]>([]);
  const navigate = useNavigate();

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (name)
      Api.get("cultural_assets/search", { params: { name } }).then(
        (response) => {
          setList(response.data);
        }
      );
  };

  const saveClickHandler = (cul: CulXml) => {
    Api.post("cultural_assets", cul).then((response) => {
      appSuccessMsg({ msg: "등록되었습니다." });
      navigate(-1);
    });
  };

  return (
    <MyContainer title="문화재 등록">
      <>
        <Row>
          <Form onSubmit={submitHandler}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="문화재 명 검색"
                aria-label=""
                defaultValue={name}
                onChange={(e: any) => setName(e.target.value)}
              />
              <Button variant="outline-secondary" type="submit">
                검색
              </Button>
            </InputGroup>
          </Form>
        </Row>

        <h5 className="fw-bold">문화재 상세 자료</h5>

        <Table>
          <thead>
            <tr>
              <th>지정 번호</th>
              <th>문화재 명</th>
              <th>중복 여부</th>
            </tr>
          </thead>
          <tbody>
            {list.map((cul: any) => (
              <tr key={cul.no}>
                <td>{cul.ccbaCpno}</td>
                <td>{cul.ccbaMnm1}</td>
                <td>{cul.saved ? "중복" : "중복 없음"}</td>
                <td>
                  {cul.saved ? (
                    <></>
                  ) : (
                    <Button onClick={() => saveClickHandler(cul)}>선택</Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    </MyContainer>
  );
}

export default CulResigst;
