import { appErrorMsg } from "assets/js/common";
import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export const Api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // 기본 서버 주소 입력
  headers: {
    "Access-Control-Allow-Origin": "*",
    withCredentials: true,
  },
});

Api.interceptors.request.use((config) => {
  if (config.headers === undefined || config.headers["Authorization"])
    return config;
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const token = auth?.jwt;

  if (token && config.headers)
    config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (isNetworkErr(error)) {
      toast.error("서버에 접속할 수 없습니다.", {
        autoClose: 1000,
        pauseOnFocusLoss: false,
      });
    } else if (isAuthErr(error)) {
      localStorage.removeItem("auth");
      window.location.reload();
    } else if (isErrorMessage(error)) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        pauseOnFocusLoss: false,
      });
    } else if (isAccessDenied(error)) {
      appErrorMsg({ msg: "권한이 없습니다." });
    } else {
      return Promise.reject(error);
    }
    throw new Error("API 에러 발생");
  }
);

const isNetworkErr = (error: any) => {
  return error.response.status === 0 && error.code === "ERR_NETWORK";
};

const isAuthErr = (error: any) => {
  return error.response.status === 401;
};

const isErrorMessage = (error: any) => {
  return (
    error.response.status === 400 &&
    error.response?.data.code === "E001" &&
    error.response.data.message
  );
};

const isAccessDenied = (error: any) => {
  return error.response.status === 500 && error.response?.data.code === "A001";
};
