import { useEffect, useState } from "react";
import { Image, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import MyPagination from "components/MyPagination";
import { commonSearch, hasReviewImage } from "assets/js/common";
import { Review } from "model/Review";
import SelectFilter from "components/display/SelectFilter";
import InputFilter from "components/display/InputFilter";
import FilterResetBtn from "components/display/FilterResetBtn";
import ImageIcon from "assets/images/icon/image.png";

function Reviews() {
  const [rows, setRows] = useState<Review[]>([]);
  const [data, setData] = useState<any>();
  const search = commonSearch("/reviews", { setData, setRows });
  useEffect(() => commonSearch("/reviews", { setData, setRows })(), []);

  const convertStatus = (status: number) => {
    switch (status) {
      case 0:
        return "일반";
      case 1:
        return "블라인드";
      case 2:
        return "경고";
      case 3:
        return "블랙리스트";
    }
    return "";
  };
  return (
    <MyContainer title="리뷰 관리">
      <div>
        <div>
          <Row className="mb-3">
            <SelectFilter search={search} name="status">
              <option value="">상태</option>
              <option value="0">일반</option>
              <option value="1">블라인드</option>
              <option value="2">경고</option>
              <option value="3">블랙리스트</option>
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
          <InputFilter search={search}>
            <option value="userEmail">이메일</option>
            <option value="culName">문화재명</option>
          </InputFilter>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>이메일</th>
              <th>내용</th>
              <th></th>
              <th>문화재</th>
              <th>등록일</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows?.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`./${row.idx}`}>
                      {data?.number * data.size + index + 1}
                    </Link>
                  </td>
                  <td>{row.user?.email}</td>
                  <td className="text-truncate" style={{ maxWidth: "15rem" }}>
                    {row.reviewDetails[0].text}
                  </td>
                  <td>
                    {hasReviewImage(row) && (
                      <Image src={ImageIcon} style={{ width: "1.5rem" }} />
                    )}
                  </td>
                  <td className="text-truncate" style={{ maxWidth: "15rem" }}>
                    {row.culturalAsset.ccbaMnm1}
                  </td>
                  <td>{row.createdAt}</td>
                  <td>{convertStatus(row.status)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
}

export default Reviews;
