import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { useNavigate, useParams } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import { Review } from "model/Review";
import ReviewContent from "./ReviewContent";
import DeclarationContent from "./DetailContent";

function ReviewView() {
  const navigate = useNavigate();
  const { idx } = useParams() as { idx: string };
  const [review, setReview] = useState<Review>();

  useEffect(() => {
    Api.get(`/reviews/${idx}`).then((response) => {
      setReview(response.data);
    });
  }, [idx]);

  if (!review) return <></>;
  return (
    <MyContainer title="리뷰 상세보기">
      <Row>
        <ReviewContent
          reviewIdx={parseInt(idx)}
          review={review}
          setReview={setReview}
        />
        <DeclarationContent reviewIdx={parseInt(idx)} />
        <Row>
          <Col className="d-flex justify-content-end mt-3">
            <Button
              variant="primary"
              className="ms-3"
              onClick={() => navigate(-1)}
            >
              확인
            </Button>
          </Col>
        </Row>
      </Row>
    </MyContainer>
  );
}

export default ReviewView;
