import { appSuccessMsg } from "assets/js/common";
import SortList from "components/display/SortList";
import { Api } from "lib/axios/Api";
import { Term } from "model/types";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, ListGroupItem, Modal, Row } from "react-bootstrap";

interface TermSortProps {
  title: string;
  show: boolean;
  setShow: Function;
  search?: Function;
}
const TermSort = ({ title, show, setShow, search }: TermSortProps) => {
  const [terms, setTerms] = useState<Term[]>([]);
  useEffect(() => {
    if (show)
      Api.get("terms/sort").then((response) => {
        setTerms(response.data);
      });
  }, [show]);

  const saveSortClickHandler = () => {
    const newTerms = terms.map((term, i) => {
      return { ...term, sort: i };
    });
    Api.put("terms/list", newTerms).then(() => {
      appSuccessMsg({ msg: "순서가 변경되었습니다." });
      setShow(false);
      if (search) search();
    });
  };
  return (
    <Modal show={show} onHide={() => setShow(false)} centered backdrop="static">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SortList list={terms} setList={setTerms}>
          {terms.map((term) => (
            <ListGroupItem draggable={true} key={term.idx}>
              <Row>
                <Col>{term.title}</Col>
                <Col className="col-auto">
                  {term.status === 1 ? "활성" : "비활성"}
                </Col>
              </Row>
            </ListGroupItem>
          ))}
        </SortList>
        <Row>
          <Col>
            <Button
              variant="danger"
              className="w-100"
              onClick={() => setShow(false)}
            >
              취소
            </Button>
          </Col>
          <Col>
            <Button className="w-100" onClick={saveSortClickHandler}>
              저장
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TermSort;
