import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import MyContainer from 'components/layouts/MyContainer';
import { Api } from 'lib/axios/Api';
import { InquiryType } from 'model/types';
import { appSuccessMsg, commonSwalConfirm } from 'assets/js/common';

export default function InquiryTypes() {
  const [ inquiryTypes, setInquiryTypes ] = useState<InquiryType[]>([]);
  const [ newInquiryType, setNewInquiryType ] = useState<Partial<InquiryType>>({status: 0});

  useEffect(() => {
    Api.get("inquiry_types").then((response) => setInquiryTypes(response.data));
  }, []);

  const reloadData = () => {
    Api.get("inquiry_types").then((response) => setInquiryTypes(response.data));
  }

  const addClickHandler = async () => {
    if(newInquiryType.title) {
      const result = await commonSwalConfirm({title: "추가하시겠습니까?", confirmButtonText: "추가"});
      if(result.isConfirmed) {
        Api.post("/inquiry_types", newInquiryType).then((response) => {
          appSuccessMsg({msg: "추가되었습니다."});
          reloadData();
          setNewInquiryType({status: 0});
        });
      }
    }
  }

  const updateClickHandler = async (inquiryType: InquiryType) => {
    const result = await commonSwalConfirm({title: "수정하시겠습니까?", confirmButtonText: "수정"});
    if(result.isConfirmed) {
      Api.put("inquiry_types", inquiryType).then(() => {
        reloadData();
        appSuccessMsg({msg: "수정되었습니다."});
      });
    }
  }

  const deleteClickHandler = async (inquiryType: InquiryType) => {
    const result = await commonSwalConfirm({title: "삭제하시겠습니까?", confirmButtonText: "삭제"});
    if(result.isConfirmed) {
      Api.delete(`inquiry_types/${inquiryType.idx}`).then(() => {
        reloadData();
        appSuccessMsg({msg: "삭제되었습니다."});
      })
    }
  }

  return (
    <MyContainer title="문의 유형 관리">
      <div>
        <Row>
          {inquiryTypes.map((inquiryType: any) => (
            <InquiryTypeRow key={inquiryType.idx} inquiryType={inquiryType}/>
          ))}
        </Row>
        <hr/>
        <Row>
          <Col md="auto">
            <InputGroup className="mb-3">
              <div style={{position: 'relative'}}>
                <Form.Control 
                  style={{width: '25rem'}} 
                  value={newInquiryType.title ?? ""}
                  onChange={(e) => setNewInquiryType({...newInquiryType, title: e.target.value})}
                  maxLength={30}
                />
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  style={{position: 'absolute', right: 0, top: '.5rem'}}
                  checked={newInquiryType.status === 1}
                  onChange={(e) => setNewInquiryType({...newInquiryType, status: e.target.checked ? 1 : 0})}
                />
              </div>
              <Button variant="success" onClick={addClickHandler}>추가</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>
    </MyContainer>
  );

  function InquiryTypeRow({inquiryType}: {inquiryType: InquiryType}) {
    const [ updateInquiryType, setUpdateInquiryType ] = useState(inquiryType);
    return (
      <Row>
        <Col md="auto">
          <Form>
            <InputGroup className="mb-3">
              <div style={{position: 'relative'}}>
                <Form.Control 
                  style={{width: '25rem'}} 
                  defaultValue={updateInquiryType.title}
                  onChange={(e) => setUpdateInquiryType({...updateInquiryType, title: e.target.value})}
                />
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  style={{position: 'absolute', right: 0, top: '.5rem'}}
                  defaultChecked={updateInquiryType.status === 1}
                  onChange={(e) => setUpdateInquiryType({...updateInquiryType, status: e.target.checked ? 1 : 0})}
                />
              </div>
              <Button variant="primary" onClick={() => updateClickHandler(updateInquiryType)}>수정</Button>
              <Button variant="danger" onClick={() => deleteClickHandler(inquiryType)}>삭제</Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    )
  }
}
