import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import RegistBtn from "components/display/RegistBtn";
import { QuestionType } from "model/Question";
import MyPagination from "components/MyPagination";
import { commonSearch } from "assets/js/common";

export default function QuestionTypes() {
  const [rows, setRows] = useState<QuestionType[]>([]);
  const [data, setData] = useState<any>();
  const search = commonSearch("/question_types", { setData, setRows });
  useEffect(() => commonSearch("/question_types", { setData, setRows })(), []);
  return (
    <MyContainer title="기출시험 유형 관리">
      <div>
        <Row>
          <Col className="d-flex align-items-end justify-content-end mb-3">
            <RegistBtn />
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>등록ID</th>
              <th>기출시험 종류</th>
              <th>상태</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td key={row.idx}>
                  <Link to={`./${row.idx}`}>
                    {data?.number * data.size + index + 1}
                  </Link>
                </td>
                <td>{row.admin?.name ?? "-"}</td>
                <td>{row.name}</td>
                <td>{row.status === 1 ? "활성화" : "비활성화"}</td>
                <td>{row.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
}
