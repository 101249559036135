import { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commonSave } from "assets/js/common";
import { Admin } from "model/Admin";

const AdminView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { idx } = params;
  const [validated, setValidated] = useState(false);
  const isView = parseInt(idx as string) ? true : false;
  const [admin, setAdmin] = useState<Partial<Admin>>({});

  const [invalidByPassword, setInvalidByPassword] = useState(false);
  const [invalidByConfirm, setInvalidByConfirm] = useState(false);
  const pwdRef = useRef() as React.RefObject<HTMLInputElement>;
  const pwdConfirmRef = useRef() as React.RefObject<HTMLInputElement>;

  useEffect(() => {
    if (isView)
      Api.get(`/admins/${idx}`).then((response) => setAdmin(response.data));
  }, [idx, isView]);

  const submitHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (invalidByPassword || invalidByConfirm) return;
    if (e.target.checkValidity()) {
      commonSave({ send });
    }
    setValidated(true);
  };

  const send: any = () => {
    if (isView) {
      return Api.put(`/admins/${idx}`, admin);
    } else {
      return Api.post(`/admins`, {
        ...admin,
        password: pwdRef.current?.value,
      }).then(() => navigate("/admin/admins"));
    }
  };

  const updateModel = (fieldsToUpdate: Partial<Admin>) => {
    if (admin) setAdmin({ ...admin, ...fieldsToUpdate });
  };

  const checkPassword = () => {
    let password = pwdRef.current?.value;
    let confirmPwd = pwdConfirmRef.current?.value;
    setInvalidByConfirm(password !== confirmPwd);
    updateModel({ password: password });
    if (password) {
      setInvalidByPassword(
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/.test(
          password
        )
      );
    }
  };

  return (
    <MyContainer title={isView ? "어드민 계정 상세" : "어드민 계정 등록"}>
      <Form validated={validated} onSubmit={submitHandler}>
        <ToastContainer />
        {isView && (
          <Row>
            <Form.Group as={Col} controlId="idx" className="mb-3 col-auto">
              <Form.Label>No</Form.Label>
              <Form.Control type="text" defaultValue={admin.idx} readOnly />
            </Form.Group>
          </Row>
        )}

        <Row>
          <Form.Group as={Col} controlId="id" className="mb-3 col-auto">
            <Form.Label>ID</Form.Label>
            <Form.Control
              type="text"
              defaultValue={admin.id}
              onChange={(e) => updateModel({ id: e.target.value })}
              required
              readOnly={isView}
              maxLength={20}
              minLength={2}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="email" className="mb-3 col-auto">
            <Form.Label>email</Form.Label>
            <Form.Control
              type="email"
              defaultValue={admin.email}
              onChange={(e) => updateModel({ email: e.target.value })}
              required
              maxLength={286}
            />
          </Form.Group>
          {isView && (
            <Col className="pt-3">
              <Link to="./pwd_change">
                <Button className="mt-3">비밀번호 변경하기</Button>
              </Link>
            </Col>
          )}
        </Row>

        {!isView && (
          <Row>
            <Form.Group as={Col} controlId="password" className="mb-3 col-auto">
              <Form.Label>비밀번호</Form.Label>
              <Form.Control
                type="password"
                isInvalid={invalidByPassword}
                ref={pwdRef}
                onChange={checkPassword}
                required
              />
              <Form.Control.Feedback type="invalid">
                영문 대/소문자, 숫자 특문 8~15 문자
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              controlId="password_confirm"
              className="mb-3 col-auto"
            >
              <Form.Label>비밀번호 확인</Form.Label>
              <Form.Control
                type="password"
                isInvalid={invalidByConfirm}
                ref={pwdConfirmRef}
                onChange={checkPassword}
                required
              />
              <Form.Control.Feedback type="invalid">
                비밀번호가 일치하지 않습니다.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        )}

        <Row>
          <Col>
            <Form.Label>권한</Form.Label>
            {admin.idx === undefined ? (
              <Form.Select
                className="w-auto mb-3"
                required
                onChange={(e) =>
                  updateModel({ level: parseInt(e.target.value) })
                }
              >
                <option value="">권한</option>
                <option value="0">Level 1</option>
                <option value="1">Level 2</option>
              </Form.Select>
            ) : (
              <Form.Control
                className="w-auto mb-3"
                disabled
                value={admin.level === 0 ? "LEVEL 1" : "LEVEL 2"}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="name" className="mb-3 col-auto">
            <Form.Label>이름</Form.Label>
            <Form.Control
              type="text"
              defaultValue={admin.name}
              onChange={(e) => updateModel({ name: e.target.value })}
              required
              minLength={2}
              maxLength={15}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            controlId="phoneNumber"
            className="mb-3 col-auto"
          >
            <Form.Label>전화번호</Form.Label>
            <Form.Control
              type="tel"
              defaultValue={admin.phoneNumber}
              onChange={(e) => updateModel({ phoneNumber: e.target.value })}
              required
              minLength={8}
              maxLength={12}
            />
          </Form.Group>
        </Row>
        {isView && (
          <Row>
            <Form.Group
              as={Col}
              controlId="created_at"
              className="mb-3 col-auto"
            >
              <Form.Label>등록일시</Form.Label>
              <Form.Control
                type="text"
                defaultValue={admin.createdAt}
                readOnly
              />
            </Form.Group>

            <Form.Group
              as={Col}
              controlId="last_login_at"
              className="mb-3 col-auto"
            >
              <Form.Label>마지막 로그인 시간</Form.Label>
              <Form.Control
                type="tel"
                defaultValue={admin?.lastLoginAt}
                readOnly
              />
            </Form.Group>
            <Col>
              <Form.Label>상태</Form.Label>
              <Form.Select
                className="col-auto w-auto mb-3"
                name="status"
                required
                value={admin?.status?.toString()}
                onChange={(e) => {
                  updateModel({ status: parseInt(e.target.value) });
                }}
              >
                <option value="1">활성</option>
                <option value="0">비활성</option>
              </Form.Select>
            </Col>
          </Row>
        )}

        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              variant="danger"
              className="ms-3"
              onClick={() => navigate(-1)}
            >
              취소
            </Button>
            <Button type="submit" variant="primary" className="ms-3">
              저장
            </Button>
          </Col>
        </Row>
      </Form>
    </MyContainer>
  );
};

export default AdminView;
