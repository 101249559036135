import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import RegistBtn from "components/display/RegistBtn";
import SelectFilter from "components/display/SelectFilter";
import MyPagination from "components/MyPagination";
import { commonSearch } from "assets/js/common";
import { Faq } from "model/types";
import FaqSort from "./FaqSort";
import FilterResetBtn from "components/display/FilterResetBtn";

export default function Faqs() {
  const [data, setData] = useState<any>();
  const [rows, setRows] = useState<Faq[]>([]);
  const [show, setShow] = useState(false);

  const search = commonSearch("/faqs", { setData, setRows });
  useEffect(() => commonSearch("/faqs", { setData, setRows })(), []);

  return (
    <MyContainer title="자주 묻는 질문">
      <div>
        <div>
          <Row className="mb-3">
            <SelectFilter search={search} name="status">
              <option value="">상태</option>
              <option value="1">활성</option>
              <option value="0">비활성</option>
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
        </div>
        <Row className="mb-3">
          <Col className="d-flex align-items-end justify-content-end mb-3">
            <Button
              variant="primary"
              className="ms-3"
              onClick={() => setShow(true)}
            >
              순서 변경
            </Button>
            <RegistBtn />
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>제목</th>
              <th>등록자ID</th>
              <th>등록일</th>
              <th>순서</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td key={row.idx}>
                  <Link to={`./${row.idx}`}>
                    {data?.number * data.size + index + 1}
                  </Link>
                </td>
                <td>{row.title}</td>
                <td>{row.admin.id}</td>
                <td>{row.createdAt}</td>
                <td>{row.sort !== undefined ? row.sort + 1 : "-"}</td>
                <td>{row.status === 0 ? "비활성" : "활성"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
        <FaqSort
          title="자주 묻는 질문 순서 변경"
          show={show}
          setShow={setShow}
          search={search}
        />
      </div>
    </MyContainer>
  );
}
