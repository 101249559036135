import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function ExcelBtn() {
  const clickHandler = () => {
    toast.error("아직 구현되지 않았습니다...", { autoClose: 1000 });
  };
  return (
    <Link to="./">
      <Button variant="success" className="ms-3" onClick={() => clickHandler()}>
        Excel
      </Button>
    </Link>
  );
}
