import MyContainer from "components/layouts/MyContainer";
import { useState } from "react";
import { Col, Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";

const Permissions = () => {
  const [selected, setSelected] = useState("LEVEL 1");
  return (
    <MyContainer title="권한 관리">
      <div>
        <Row>
          <Col className="mb-3" xs={12} md={3}>
            <ListGroup>
              <ListGroupItem
                active={selected === "LEVEL 2"}
                onClick={() => setSelected("LEVEL 2")}
                role="button"
              >
                LEVEL 2
              </ListGroupItem>
              <ListGroupItem
                active={selected === "LEVEL 1"}
                onClick={() => setSelected("LEVEL 1")}
                role="button"
              >
                LEVEL 1
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col className="col-6">
            <ListGroup>
              <ListGroupItem className="fw-bold bg-secondary text-white d-flex justify-content-between">
                대시보드
                <Form.Check disabled checked value="" />
              </ListGroupItem>

              <ListGroupItem className="fw-bold bg-secondary text-white d-flex justify-content-between">
                어드민 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                어드민 계정 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                권한 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                기출 시험 유형 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                신고 유형 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                문의 유형 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>

              <ListGroupItem className="fw-bold bg-secondary text-white d-flex justify-content-between">
                서비스 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                문화재 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                회원 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                신고 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                기출 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                리뷰 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>

              <ListGroupItem className="fw-bold bg-secondary text-white d-flex justify-content-between">
                고객센터 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                공지사항 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                자주묻는 질문 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                문의 관리
                <Form.Check disabled checked value="" />
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between">
                약관 관리
                <Form.Check
                  disabled
                  checked={selected === "LEVEL 2"}
                  value=""
                />
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </div>
    </MyContainer>
  );
};

export default Permissions;
