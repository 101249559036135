import { useRef } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  deleteQueryString,
  getQueryString,
  setQueryString,
} from "assets/js/common";

const QUERY_STRING_INPUT_NAME = "inputName";
const InputFilter = ({ search, children }: any) => {
  const inputNameRef = useRef() as React.RefObject<HTMLSelectElement>;
  const inputValueRef = useRef() as React.RefObject<HTMLInputElement>;

  const searchHandler = () => {
    let inputName = getQueryString(QUERY_STRING_INPUT_NAME);
    if (typeof inputName === "string") {
      deleteQueryString(inputName);
      deleteQueryString(QUERY_STRING_INPUT_NAME);
    }
    if (inputNameRef?.current?.value && inputValueRef?.current?.value) {
      setQueryString(inputNameRef.current.value, inputValueRef.current.value);
      setQueryString(QUERY_STRING_INPUT_NAME, inputNameRef.current.value);
    }

    search();
  };

  const defaultSelect = () => {
    return String(getQueryString(QUERY_STRING_INPUT_NAME));
  };

  const defaultInput = () => {
    let inputName = getQueryString(QUERY_STRING_INPUT_NAME);
    if (typeof inputName === "string") return String(getQueryString(inputName));
    return "";
  };

  return (
    <>
      <Row className="mb-3">
        <Col xs="12" md="6">
          <InputGroup className="mb-3">
            <Form.Select
              style={{ flex: "0 0 8rem" }}
              ref={inputNameRef}
              defaultValue={defaultSelect()}
            >
              <option value="">SELECT</option>
              {children}
            </Form.Select>
            <Form.Control ref={inputValueRef} defaultValue={defaultInput()} />
          </InputGroup>
        </Col>
        <Col>
          <Button
            type="submit"
            variant="outline-secondary"
            id="button-addon2"
            onClick={searchHandler}
          >
            검색
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default InputFilter;
