import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MyContainer from 'components/layouts/MyContainer';
import RegistBtn from 'components/display/RegistBtn';
import MyPagination from 'components/MyPagination';
import { Admin } from 'model/Admin';
import SelectFilter from 'components/display/SelectFilter';
import InputFilter from 'components/display/InputFilter';
import { commonSearch } from 'assets/js/common';
import FilterResetBtn from 'components/display/FilterResetBtn';

const Admins = () => {
  const [data, setData] = useState<any>();
  const [rows, setRows] = useState<Admin[]>([]);
  const search = commonSearch("/admins", {setData, setRows});
  useEffect(() => commonSearch("/admins", {setData, setRows})(), []);

  return (
    <MyContainer title="어드민 계정 관리">
      <div>
        <div>
          <Row className='mb-3'>
            <SelectFilter search={search} name="status">
              <option value="">상태</option>
              <option value="1">활성</option>
              <option value="0">비활성</option>
            </SelectFilter>
            <SelectFilter search={search} name="level">
              <option value="">권한</option>
              <option value="0">LEVEL 1</option>
              <option value="1">LEVEL 2</option>
            </SelectFilter>
            <FilterResetBtn search={search}/>
          </Row>
          <InputFilter search={search}>
            <option value="id">ID</option>
            <option value="name">이름</option>
            <option value="email">Email</option>
            <option value="phone_number">전화번호</option>
          </InputFilter>
          <Row>
            <Col className="d-flex align-items-end justify-content-end mb-3">
              <RegistBtn />
            </Col>
          </Row>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>ID</th>
              <th>이름</th>
              <th>Email</th>
              <th>전화번호</th>
              <th>권한</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {
              rows.map((row, index) => (
                <tr key={index}>
                  <td key={row.idx}><Link to={`./${row.idx}`}>{(data?.number * data.size) + index + 1}</Link></td>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>{row.email}</td>
                  <td>{row.phoneNumber}</td>
                  <td>{row.level === 0 ? "LEVEL 1" : "LEVEL 2"}</td>
                  <td>{row.status === 0 ? "비활성" : "활성"}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <MyPagination params={data} search={search}/>
      </div>
    </MyContainer>
  );
}

export default Admins;