import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

function Search(props: {
  filter: {
    input?: SearchInput;
    selectors?: SearchSelector[];
    search?: Function;
  };
}) {
  const { filter } = props;
  const { input, selectors } = filter;

  const filters: Filters = {
    selects: {},
    input: {},
  };

  const submit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  function InputSearch(input: SearchInput): JSX.Element {
    if (!input) return <></>;
    else if (input.items?.length === 1) {
      let item = input.items[0];
      filters.input.name = item.name;
      return (
        <Row>
          <Col xs="12" md="8">
            <InputGroup className="mb-3">
              <InputGroup.Text>{item.value}</InputGroup.Text>
              <Form.Control
                placeholder={input.placeholder}
                aria-label={input.placeholder}
                name={item.name}
                defaultValue={filters.input.value}
                onChange={(e: any) => (filters.input.value = e.target.value)}
              />
              <Button
                type="submit"
                variant="outline-secondary"
                id="button-addon2"
              >
                검색
              </Button>
            </InputGroup>
          </Col>
        </Row>
      );
    } else if (input.items) {
      return (
        <Row>
          <Col xs="12" md="8">
            <InputGroup className="mb-3">
              <Form.Select
                aria-label="Level Select"
                style={{ flex: "0 0 8rem" }}
                defaultValue={filters.input.name}
                onChange={(e: any) => (filters.input.name = e.target.value)}
              >
                <option>SELECT</option>
                {input.items.map(({ name, value }) => (
                  <option value={name} key={name}>
                    {value as String}
                  </option>
                ))}
              </Form.Select>
              <Form.Control
                placeholder={input.placeholder}
                aria-label={input.placeholder}
                defaultValue={filters.input.value}
                onChange={(e: any) => (filters.input.value = e.target.value)}
              />
              <Button
                type="submit"
                variant="outline-secondary"
                id="button-addon2"
              >
                검색
              </Button>
            </InputGroup>
          </Col>
        </Row>
      );
    }
    return <></>;
  }

  return (
    <Form onSubmit={submit}>
      {selectors && (
        <Row className="mb-3" md="auto">
          {selectors.map((selector, index) => (
            <Col key={index} className="mb-1">
              <Form.Select
                className="w-auto"
                name={selector.name}
                onChange={(e: any) =>
                  (filters.selects[e.target.name] = e.target.value)
                }
              >
                <option>{selector.defaultName}</option>
                {selector.item &&
                  getItems(selector.item).map(({ name, value }) => (
                    <option value={value} key={name}>
                      {name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          ))}
          <Col></Col>
        </Row>
      )}
      {input && InputSearch(input)}
    </Form>
  );
}

function getItems(item?: any[] | Function): any[] {
  if (item == null) {
    return [];
  } else if (typeof item === "function") {
    return item();
  } else {
    return item;
  }
}

export interface Filters {
  selects: any;
  input: any;
}

// export interface SearchSelector {
//   default: string,
//   name: string,
//   item?: any[] | Function,
// }

export class SearchSelector {
  defaultName: string;
  name: string;
  item: any[] | Function;

  constructor(defaultName: string, name: string, item: any[] | Function) {
    this.defaultName = defaultName;
    this.name = name;
    this.item = item;
  }
}

export class SearchInput {
  placeholder: string;
  items?: any[];

  constructor(placeholder: string, items?: any[]) {
    this.placeholder = placeholder;
    this.items = items;
  }
}

export class SearchFilter {
  selectors: SearchSelector[] | undefined;
  input: SearchInput | undefined;
  search: Function;

  constructor(
    search: Function,
    selectors?: SearchSelector[],
    input?: SearchInput
  ) {
    this.selectors = selectors;
    this.input = input;
    this.search = search;
  }
}

export default Search;
