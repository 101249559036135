import "styles/app.scss";
import { useSelector } from "react-redux";
import MyNav from "components/MyNav";
import Login from "pages/Auth/Login";
import { Routes, Route } from "react-router-dom";
import FindId from "pages/Auth/FindId";
import FindPw from "pages/Auth/FindPw";
import { ToastContainer } from "react-toastify";
import AdminPasswordChange from "pages/Admin/Admin/AdminPasswordChange";
import Dashboard from "pages/Dashboard/Dashboard";
import Admins from "pages/Admin/Admin/Admins";
import AdminView from "pages/Admin/Admin/AdminView";
import QuestionTypes from "pages/Admin/QuestionTypes/QuestionTypes";
import QuestionTypeView from "pages/Admin/QuestionTypes/QuestionTypeView/QuestionTypeView";
import DeclarationTypes from "pages/Admin/DeclarationTypes/DeclarationTypes";
import InquiryTypes from "pages/Admin/InquiryTypes/InquiryTypes";
import CulturalAssets from "pages/Service/CulturalAssets/CulturalAssets";
import CAView from "pages/Service/CulturalAssets/CulView";
import CAResigst from "pages/Service/CulturalAssets/CulResigst";
import Users from "pages/Service/Users/Users";
import UserView from "pages/Service/Users/UserView";
import Questions from "pages/Service/Questions/Questions";
import QuestionView from "pages/Service/Questions/QuestionView";
import Reviews from "pages/Service/Reviews/Reviews";
import Notices from "pages/Help/Notices/Notices";
import NoticeView from "pages/Help/Notices/NoticeView";
import Faqs from "pages/Help/Faqs/Faqs";
import FaqView from "pages/Help/Faqs/FaqView";
import Inquiries from "pages/Help/Inquiries/Inquiries";
import InquiryView from "pages/Help/Inquiries/InquiryView";
import Terms from "pages/Help/Terms/Terms";
import TermView from "pages/Help/Terms/TermView";
import TermColumnView from "pages/Help/Terms/TermColumnView";
import Permissions from "pages/Admin/Permission/Permissions";
import { isSuperAdmin } from "assets/js/common";
import ReviewView from "pages/Service/Reviews/ReviewView/Index";
import Objections from "pages/Service/Objections/Objections";
import ObjectionView from "pages/Service/Objections/ObjectionView";
import Declarations from "pages/Service/Declarations/Declarations";
import QuestionTypeCreate from "pages/Admin/QuestionTypes/QuestionTypeCreate";

const Admin = () => {
  const auth = useSelector((state: any) => state.auth);

  if (auth.jwt)
    return (
      <>
        <MyNav />
        <ToastContainer />
        <Routes>
          <Route path="*" element={<Dashboard />} />

          {isSuperAdmin(auth.user.role) && (
            <Route path="admin">
              <Route path="admins">
                <Route index element={<Admins />} />
                <Route path=":idx">
                  <Route index element={<AdminView />} />
                  <Route path="pwd_change" element={<AdminPasswordChange />} />
                </Route>
              </Route>
              <Route path="permissions" element={<Permissions />} />
              <Route path="question_types">
                <Route index element={<QuestionTypes />} />
                <Route path="regist" element={<QuestionTypeCreate />} />
                <Route path=":idx" element={<QuestionTypeView />} />
              </Route>
              <Route path="declaration_types">
                <Route index element={<DeclarationTypes />} />
              </Route>
              <Route path="inquiry_types">
                <Route index element={<InquiryTypes />} />
              </Route>
            </Route>
          )}

          <Route path="service">
            <Route path="cultural_assets">
              <Route index element={<CulturalAssets />} />
              <Route path=":idx" element={<CAView />} />
              <Route path="regist" element={<CAResigst />} />
            </Route>
            <Route path="users">
              <Route index element={<Users />} />
              <Route path=":idx" element={<UserView />} />
            </Route>
            <Route path="declarations">
              <Route index element={<Declarations />} />
            </Route>

            <Route path="questions">
              <Route index element={<Questions />} />
              <Route path=":idx" element={<QuestionView />} />
            </Route>
            <Route path="reviews">
              <Route index element={<Reviews />} />
              <Route path=":idx" element={<ReviewView />} />
            </Route>
            <Route path="objections">
              <Route index element={<Objections />} />
              <Route path=":idx" element={<ObjectionView />} />
            </Route>
          </Route>

          <Route path="help">
            <Route path="notices">
              <Route index element={<Notices />} />
              <Route path=":idx" element={<NoticeView />} />
            </Route>
            <Route path="faqs">
              <Route index element={<Faqs />} />
              <Route path=":idx" element={<FaqView />} />
            </Route>
            <Route path="inquiries">
              <Route index element={<Inquiries />} />
              <Route path=":idx" element={<InquiryView />} />
            </Route>
            {isSuperAdmin(auth.user.role) && (
              <Route path="terms">
                <Route index element={<Terms />} />
                <Route path=":idx" element={<TermView />} />
                <Route
                  path=":parentIdx/columns/:idx"
                  element={<TermColumnView />}
                />
                <Route path=":parentIdx/columns" element={<TermColumnView />} />
              </Route>
            )}
          </Route>
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="find_id" element={<FindId />} />
        <Route path="find_pw" element={<FindPw />} />
      </Routes>
    </>
  );
};

export default Admin;
