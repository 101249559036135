import { Api } from "lib/axios/Api";
import { useEffect, useState } from "react";
import { Badge, Col, Image, ListGroup, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DeclarationModal from "./DeclarationModal";
import { ReviewDetailAndDeclarationCnt } from "model/Review";

interface Props {
  reviewIdx: number;
}

const DetailContent = ({ reviewIdx }: Props) => {
  const [reviewDetails, setReviewDetails] = useState<
    ReviewDetailAndDeclarationCnt[]
  >([]);

  useEffect(() => {
    Api.get(`/review_details/${reviewIdx}`).then((response) =>
      setReviewDetails(response.data)
    );
  }, [reviewIdx]);

  const declarationClickHandler = async (reviewDetailIdx?: number) => {
    const declarations = await Api.get(
      `/declarations/reviewDetail/${reviewDetailIdx}`
    ).then((response) => response.data);

    const MySwal = withReactContent(Swal);

    MySwal.fire({
      html: <DeclarationModal declarations={declarations} />,
      confirmButtonText: "확인",
      width: window.screen.width < 800 ? "100%" : 800,
    });
  };

  return (
    <Row>
      <Row className="mb-3">
        <ListGroup as="ol" numbered>
          {reviewDetails.map((reviewDetail) => (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
              key={reviewDetail.idx}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{reviewDetail.text}</div>
                <Row>
                  {reviewDetail.reviewImageUrl1 && (
                    <Col className="mt-3">
                      <Image src={reviewDetail.reviewImageUrl1} />
                    </Col>
                  )}
                  {reviewDetail.reviewImageUrl2 && (
                    <Col className="mt-3">
                      <Image src={reviewDetail.reviewImageUrl2} />
                    </Col>
                  )}
                  {reviewDetail.reviewImageUrl3 && (
                    <Col className="mt-3">
                      <Image src={reviewDetail.reviewImageUrl3} />
                    </Col>
                  )}
                </Row>
              </div>
              <Row className="flex-column h-100 text-end">
                <Col>
                  <Badge
                    bg={
                      reviewDetail.declarationCheck === 1 ? "primary" : "danger"
                    }
                    pill
                    role="button"
                    onClick={() => declarationClickHandler(reviewDetail.idx)}
                  >
                    {reviewDetail.declarationCnt}
                  </Badge>
                </Col>
                <Col className="align-items-end">
                  <div className="mt-3 text-secondary">
                    {reviewDetail.createdAt}
                  </div>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Row>
    </Row>
  );
};

export default DetailContent;
