import { ChangeEvent } from "react";
import { Col, Form } from "react-bootstrap";
import {
  deleteQueryString,
  getQueryString,
  setQueryString,
} from "assets/js/common";

const SelectFilter = ({ search, name, children }: any) => {
  const ChangeHandler = (el: ChangeEvent<HTMLSelectElement>) => {
    if (el.target.value) setQueryString(name, el.target.value);
    else deleteQueryString(name);
    search(el);
  };

  return (
    <Col md="auto">
      <Form.Select
        defaultValue={String(getQueryString(name))}
        onChange={ChangeHandler}
      >
        {children}
      </Form.Select>
    </Col>
  );
};

export default SelectFilter;
