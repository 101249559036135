import ExcelBtn from "components/display/ExcelBtn";
import RegistBtn from "components/display/RegistBtn";
import Search from "components/Search";
import { Col, Container, Row } from "react-bootstrap";

interface Props {
  title?: string;
  isRegist?: boolean;
  isExcel?: boolean;
  isSearch?: boolean;
  searchFilter?: any;
  children?: JSX.Element;
}

export default function MyContainer(props: Props) {
  const btns =
    props.isRegist || props.isExcel || props.isSearch ? (
      <Row className="mb-3">
        {props.isSearch && (
          <Col xs="8" md="8">
            <Search filter={props.searchFilter} />
          </Col>
        )}
        <Col className="d-flex align-items-end justify-content-end mb-3">
          {props.isExcel && <ExcelBtn />}
          {props.isRegist && <RegistBtn />}
        </Col>
      </Row>
    ) : (
      <></>
    );

  return (
    <Container className="p-3" fluid>
      {props.title && <h4 className="fw-bold mb-5">{props.title}</h4>}
      {btns}
      {props.children}
    </Container>
  );
}
