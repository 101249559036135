import { appSuccessMsg } from "assets/js/common";
import SortList from "components/display/SortList";
import { Api } from "lib/axios/Api";
import { Faq } from "model/types";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, ListGroupItem, Modal, Row } from "react-bootstrap";

interface FaqSortProps {
  title: string;
  show: boolean;
  setShow: Function;
  search?: Function;
}
const FaqSort = ({ title, show, setShow, search }: FaqSortProps) => {
  const [faqs, setFaqs] = useState<Faq[]>([]);
  useEffect(() => {
    if (show)
      Api.get("faqs/sort").then((response) => {
        setFaqs(response.data);
      });
  }, [show]);

  const saveSortClickHandler = () => {
    const newFaqs = faqs.map((faq, i) => {
      return { ...faq, sort: i };
    });
    Api.put("faqs/list", newFaqs).then(() => {
      appSuccessMsg({ msg: "순서가 변경되었습니다." });
      setShow(false);
      if (search) search();
    });
  };
  return (
    <Modal show={show} onHide={() => setShow(false)} centered backdrop="static">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SortList list={faqs} setList={setFaqs}>
          {faqs.map((faq) => (
            <ListGroupItem draggable={true} key={faq.idx}>
              <Row>
                <Col>{faq.title}</Col>
                <Col className="col-auto">
                  {faq.status === 1 ? "활성" : "비활성"}
                </Col>
              </Row>
            </ListGroupItem>
          ))}
        </SortList>
        <Row>
          <Col>
            <Button
              variant="danger"
              className="w-100"
              onClick={() => setShow(false)}
            >
              취소
            </Button>
          </Col>
          <Col>
            <Button className="w-100" onClick={saveSortClickHandler}>
              저장
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default FaqSort;
