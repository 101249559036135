import QueryString from "query-string";
import { Api } from "lib/axios/Api";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Review } from "model/Review";
import { User } from "model/User";

export function commonSearch(url: string, { setData, setRows }: any) {
  return () => {
    let params: any = QueryString.parse(window.location.search);
    params.page--;
    if (!params.size) params.size = 10;
    Api.get(url, { params }).then((response) => {
      console.log(response);
      setData(response.data);
      setRows(response.data.content);
    });
  };
}

export function getQueryString(name: string) {
  let qs = QueryString.parse(window.location.search);
  return qs[name];
}

export function setQueryString(name: string, value: string | number) {
  let qs = QueryString.parse(window.location.search);
  qs[name] = value.toString();
  window.history.pushState(null, "", `?${QueryString.stringify(qs)}`);
}

export function deleteQueryString(name: string) {
  let qs = QueryString.parse(window.location.search);
  delete qs[name];
  window.history.pushState(null, "", `?${QueryString.stringify(qs)}`);
}

interface commonSaveProps {
  send: Function;
  title?: String;
}
export function commonSave(props: commonSaveProps) {
  Swal.fire({
    title: props.title || "저장하시겠습니까?",
    showDenyButton: true,
    confirmButtonText: "저장",
    denyButtonText: "취소",
  }).then((result) => {
    if (result.isConfirmed) {
      let id = toast.loading("저장 중입니다.");
      props
        .send()
        .then(() => {
          toast.success("저장되었습니다.", {
            autoClose: 500,
            pauseOnFocusLoss: false,
          });
        })
        .catch((err: any) => {
          console.log(err);
          toast.error("저장에 실패하였습니다.", {
            autoClose: 1000,
            pauseOnFocusLoss: false,
          });
        })
        .finally(() => {
          toast.dismiss(id);
        });
    }
  });
}

interface commonSwalConfirmProps {
  title: string;
  confirmButtonText: string;
  text?: string;
}
export const commonSwalConfirm = async (props: commonSwalConfirmProps) => {
  return await Swal.fire({
    ...props,
    showDenyButton: true,
    denyButtonText: "취소",
  });
};

interface ToastProps {
  msg: string;
}
export function appErrorMsg(props: ToastProps) {
  toast.error(props.msg, { autoClose: 2000, pauseOnFocusLoss: false });
}

export function appSuccessMsg(props: ToastProps) {
  toast.success(props.msg, { autoClose: 2000, pauseOnFocusLoss: false });
}

export const validatePassword = (password: string) => {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/.test(
    password
  );
};

export const DateToYmd = (date?: Date | null) => {
  if (!date) return "";
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return `${date.getFullYear()}-${(mm > 9 ? "" : "0") + mm}-${
    (dd > 9 ? "" : "0") + dd
  }`;
};

export const isSuperAdmin = (role: string) => {
  return role === "ROLE_SUPER_ADMIN";
};

export const hasReviewImage = (review: Review): boolean => {
  const reviewDetail = review.reviewDetails[0];
  return (
    reviewDetail.reviewImageUrl1 != null ||
    reviewDetail.reviewImageUrl2 != null ||
    reviewDetail.reviewImageUrl3 != null
  );
};

export const getReviewImageArray = (review: Review): string[] => {
  const reviewDetail = review.reviewDetails[0];
  return [
    reviewDetail.reviewImageUrl1,
    reviewDetail.reviewImageUrl2,
    reviewDetail.reviewImageUrl3,
  ].filter((url) => url) as string[];
};

export const getUserNickName = (user?: User | null) => {
  return user ? user.nickName : "비사용자";
};
