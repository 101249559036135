import {
  appErrorMsg,
  commonSwalConfirm,
  hasReviewImage,
} from "assets/js/common";
import { appSuccessMsg } from "assets/js/common";
import { getReviewImageArray } from "assets/js/common";
import { getUserNickName } from "assets/js/common";
import { Api } from "lib/axios/Api";
import { Review } from "model/Review";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  reviewIdx: number;
  review: Review;
  setReview: Function;
}

const reasonArr = [
  "욕설 비방글",
  "도배",
  "음란성/선정성",
  "광고/홍보물 기재/영리목적",
  "개인정보 노출/ 불법정보 노출",
];

const ReviewContent = ({ reviewIdx, review, setReview }: Props) => {
  const MySwal = withReactContent(Swal);

  const companionClickHander = async () => {
    const result = await commonSwalConfirm({
      title: "반려하시겠습니까?",
      confirmButtonText: "반려",
    });
    if (result.isConfirmed) {
      const data = {
        idx: reviewIdx,
        status: 0,
      };

      Api.put(`reviews/${reviewIdx}`, data).then((response) => {
        window.location.reload();
        appSuccessMsg({ msg: "처리가 완료되었습니다." });
      });
    }
  };

  const resultClickHandler = (status: number) => {
    if (!review) return;
    MySwal.fire({
      html: (
        <div>
          <h5>사유</h5>
          <div className="p-1">
            {reasonArr.map((text) => (
              <div className="text-start p-2 border" key={text}>
                <label className="w-100">
                  <input
                    type="radio"
                    className="mx-2"
                    value={text}
                    name="black_add_memo"
                  />{" "}
                  {text}
                </label>
              </div>
            ))}
            <div className="text-start p-2 border">
              <label className="w-100">
                <input
                  type="radio"
                  className="mx-2"
                  name="black_add_memo"
                  value=""
                  defaultChecked
                />{" "}
                기타
              </label>
            </div>
          </div>
        </div>
      ),
      showDenyButton: true,
      confirmButtonText: "확인",
      denyButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let selected = document.querySelector(
          "[name=black_add_memo]:checked"
        ) as HTMLInputElement;
        let value = selected.value;

        if (!value) {
          let { value: text, isConfirmed } = await MySwal.fire({
            input: "textarea",
            inputLabel: "기타",
            inputPlaceholder: "사유를 입력해주세요.",
            inputAttributes: { "aria-label": "사유를 입력해주세요." },
            showDenyButton: true,
            confirmButtonText: "확인",
            denyButtonText: "취소",
          });
          if (!isConfirmed) return false;
          value = text;
        }

        if (!value) {
          appErrorMsg({ msg: "사유가 입력되지 않았습니다." });
          return false;
        }

        const data = {
          idx: reviewIdx,
          status,
          reason: value,
        };

        Api.put(`reviews/${reviewIdx}`, data).then((response) => {
          window.location.reload();
          appSuccessMsg({ msg: "처리가 완료되었습니다." });
        });
      }
    });
  };

  const convertReviewStatus = (row: Review) => {
    switch (row.status) {
      case 0:
        return "대기";
      case 1:
        return "블라인드";
      case 2:
        return "경고";
      case 3:
        return "블랙리스트";
      default:
        return "ERROR";
    }
  };

  const convertUserStatus = (row: Review) => {
    switch (row.user?.status) {
      case 0:
        return "일반";
      case 1:
        return `경고(${row.user?.warningCnt})`;
      case 2:
        return "블랙리스트";
      default:
        return "ERROR";
    }
  };

  return (
    <Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="idx" md="3">
          <Form.Label>No</Form.Label>
          <Form.Control type="text" readOnly value={review.idx} />
        </Form.Group>
        <Form.Group as={Col} controlId="idx" md="3">
          <Form.Label>경고 누적 횟수</Form.Label>
          <Form.Control type="text" readOnly value={review.user?.warningCnt} />
        </Form.Group>
        <Form.Group as={Col} controlId="idx" md="2">
          <Form.Label>회원 상태</Form.Label>
          <Form.Control
            type="text"
            readOnly
            value={convertUserStatus(review)}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="idx" md="3">
          <Form.Label>닉네임</Form.Label>
          {review.user && (
            <Link to={`/service/users/${review.user.idx}`}>
              <Form.Control
                type="text"
                readOnly
                value={getUserNickName(review.user)}
              />
            </Link>
          )}

          {!review.user && (
            <Form.Control
              type="text"
              readOnly
              value={getUserNickName(review.user)}
            />
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="idx" md="3">
          <Form.Label>등록일</Form.Label>
          <Form.Control type="text" readOnly value={review.createdAt} />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="idx" md="3">
          <Form.Label>이메일</Form.Label>
          <Form.Control type="text" readOnly value={review.user?.email} />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="idx" md="8">
          <Form.Label>문화재명</Form.Label>
          <Form.Control
            type="text"
            readOnly
            value={review.culturalAsset.ccbaMnm1}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="idx" md="8">
          <Form.Label>내용</Form.Label>
          <Form.Control
            as="textarea"
            readOnly
            value={review.reviewDetails[0].text}
            rows={4}
            className="noresize"
          />
        </Form.Group>
      </Row>

      {hasReviewImage(review) && (
        <Row>
          <Form.Label>리뷰 이미지</Form.Label>
          {getReviewImageArray(review).map((imageUrl, i) => (
            <Col className="col-auto" key={i}>
              <Link
                to={`/image/${encodeURIComponent(imageUrl)}`}
                target="_blank"
              >
                <Image src={imageUrl} style={{ maxHeight: "10rem" }} />
              </Link>
            </Col>
          ))}
        </Row>
      )}

      <Row className="mb-3">
        <Form.Group as={Col} controlId="idx" md="2">
          <Form.Label>상태</Form.Label>
          <Form.Control
            type="text"
            readOnly
            value={convertReviewStatus(review)}
          />
        </Form.Group>

        <Col>
          <Row className="mt-4 pt-2">
            <Col className="">
              <>
                {review.status <= 0 && (
                  <Button
                    variant="danger"
                    className="ms-3"
                    onClick={() => resultClickHandler(1)}
                  >
                    블라인드
                  </Button>
                )}
                {review.status <= 1 && (
                  <Button
                    variant="danger"
                    className="ms-3"
                    onClick={() => resultClickHandler(2)}
                  >
                    경고 1회
                  </Button>
                )}
                {review.status <= 2 && (
                  <Button
                    variant="danger"
                    className="ms-3"
                    onClick={() => resultClickHandler(3)}
                  >
                    블랙리스트
                  </Button>
                )}
                {(review.declarations ?? []).length > 0 &&
                  review.status === 0 && (
                    <Button
                      variant="danger"
                      className="ms-3"
                      onClick={() => companionClickHander()}
                    >
                      신고 반려
                    </Button>
                  )}
              </>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default ReviewContent;
