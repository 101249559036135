import { Api } from "lib/axios/Api";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CulturalAsset } from "model/CulturalAsset";
import MyContainer from "components/layouts/MyContainer";
import Swal from "sweetalert2";
import { appSuccessMsg } from "assets/js/common";
import CulImages from "./CulImages";

function CulView() {
  const navigate = useNavigate();
  const { idx } = useParams() as { idx: string };
  const [cul, setCul] = useState<Partial<CulturalAsset>>({});
  const [oldCul, setOldCul] = useState<Partial<CulturalAsset>>({});
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [questonCount, setQuestonCount] = useState<number>(0);

  useEffect(() => {
    Api.get(`/cultural_assets/${idx}`).then((response) => {
      setCul(response.data);
      setOldCul(response.data);
      Api.get(`reviews/count/cul/${idx}`).then((response) => {
        setReviewCount(response.data);
      });
      Api.get(`questions/count/cul/${idx}`).then((response) => {
        setQuestonCount(response.data);
      });
    });
  }, [idx]);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    Swal.fire({
      title: "상태를 변경하시겠습니까?",
      showDenyButton: true,
      confirmButtonText: "변경",
      denyButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.put(`/cultural_assets/${idx}`, cul).then((response) => {
          setCul(response.data);
          setOldCul(response.data);
          appSuccessMsg({ msg: "저장 되었습니다." });
        });
      }
    });
  };

  const updateModel = (fieldsToUpdate: Partial<CulturalAsset>) => {
    if (cul) setCul({ ...cul, ...fieldsToUpdate });
  };

  const updateClickHandler = () => {
    Api.get(`cultural_assets/get_update_data/${idx}`).then((response) => {
      setCul({
        ...response.data,
        idx: oldCul.idx,
        status: oldCul.status,
        createdAt: oldCul.createdAt,
        updatedAt: oldCul.updatedAt,
        deletedAt: oldCul.deletedAt,
        culStory: oldCul.culStory,
      });
    });
  };

  return (
    <MyContainer title="문화재 상세">
      <Form onSubmit={submitHandler} className="cul-update-form">
        <Row className="mb-3">
          <Col className="d-flex justify-content-end">
            <Button
              variant="success"
              className="ms-3 bg-gradient me-3"
              onClick={updateClickHandler}
            >
              갱신
            </Button>
            <div className="d-flex align-items-center justify-content-center">
              <Form.Check
                inline
                label="활성화"
                name="status"
                type="radio"
                value="1"
                id="status_1"
                checked={Boolean(cul.status)}
                onChange={(e) => updateModel({ status: 1 })}
              />
              <Form.Check
                inline
                label="비활성화"
                name="status"
                type="radio"
                value="0"
                id="status_0"
                checked={!Boolean(cul.status)}
                onChange={(e) => updateModel({ status: 0 })}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3" md="3">
          <Form.Group as={Col} controlId="_idx" className="mb-3">
            <Form.Label>고유번호</Form.Label>
            <Form.Control type="text" readOnly defaultValue={cul.idx} />
          </Form.Group>
        </Row>
        <Row xs="1" md="2">
          <Form.Group as={Col} controlId="name" className="mb-3">
            <Form.Label>문화재 명</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={cul.ccbaMnm1}
              className={oldCul.ccbaMnm1 === cul.ccbaMnm1 ? "" : "updated"}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="ccceName" className="mb-3">
            <Form.Label>시대</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={cul.ccceName}
              className={oldCul.ccceName === cul.ccceName ? "" : "updated"}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="ccmaName" className="mb-3">
            <Form.Label>종목</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={cul.ccmaName}
              className={oldCul.ccmaName === cul.ccmaName ? "" : "updated"}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3">
            <Form.Label>분류</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={cul.gcodeName}
              className={oldCul.gcodeName === cul.gcodeName ? "" : "updated"}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>지정번호</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={cul.ccbaAsno}
              className={oldCul.ccbaAsno === cul.ccbaAsno ? "" : "updated"}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>문화재 주소</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={cul.ccbaCtcdNm}
              className={oldCul.ccbaCtcdNm === cul.ccbaCtcdNm ? "" : "updated"}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>상세 주소</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={cul.ccbaLcad}
              className={oldCul.ccbaLcad === cul.ccbaLcad ? "" : "updated"}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>메인 사진</Form.Label>
            <a
              href={cul.imageUrl ?? "#"}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none"
            >
              <Form.Control
                type="text"
                readOnly
                defaultValue={cul.imageUrl}
                className={oldCul.imageUrl === cul.imageUrl ? "" : "updated"}
              />
            </a>
          </Form.Group>
        </Row>

        <CulImages culIdx={idx} />
        <Row>
          <Form.Group as={Col} controlId="b" className="mb-3">
            <Form.Label>문화재 설명</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              readOnly
              defaultValue={cul.content}
              className={oldCul.content === cul.content ? "" : "updated"}
            />
          </Form.Group>
        </Row>

        <h5 className="fw-bold my-3">문화재 일반 자료</h5>

        <Row>
          <Form.Group as={Col} md="auto" className="mb-3">
            <Form.Label column>기출문제 수 :&nbsp;</Form.Label>
            <Link to={`/service/questions?culIdx=${idx}`}>{questonCount}</Link>
          </Form.Group>

          <Form.Group as={Col} md="auto" className="mb-3">
            <Form.Label column>리뷰 수 :&nbsp;</Form.Label>
            <Link to={`/service/reviews?culIdx=${idx}`}>{reviewCount}</Link>
          </Form.Group>
        </Row>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md="auto">
            문화유산 이야기 마당
          </Form.Label>
          <Col>
            <InputGroup>
              <Form.Control
                placeholder=""
                aria-label=""
                defaultValue={cul.culStory?.storyUrl}
                onChange={(e) =>
                  updateModel({
                    culStory: {
                      idx: parseInt(idx as string),
                      storyUrl: e.target.value,
                    },
                  })
                }
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                target="_blank"
                href={cul.culStory?.storyUrl}
              >
                보기
              </Button>
            </InputGroup>
          </Col>
        </Form.Group>

        <Col className="d-flex justify-content-end">
          <Button
            variant="danger"
            className="ms-3"
            onClick={() => navigate(-1)}
          >
            취소
          </Button>
          <Button type="submit" variant="primary" className="ms-3">
            저장
          </Button>
        </Col>
      </Form>
    </MyContainer>
  );
}

export default CulView;
