import { QuestionType, QuestionTypeColumn } from "model/Question";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { commonSwalConfirm } from "assets/js/common";
import { Api } from "lib/axios/Api";

interface ItemConpornetProps {
  column: QuestionTypeColumn;
  updateModel: (fieldsToUpdate: Partial<QuestionType>) => void;
  questionType: Partial<QuestionType>;
}

const QuestionTypeItemComponent = ({
  column,
  updateModel,
  questionType,
}: ItemConpornetProps) => {
  const columnChangeHandler = () => (date: any) => {
    if (questionType.columns) {
      column.date = date.toISOString().split("T")[0];
      updateColumn(column);
    }
  };

  const deleteHandler = async (e: any) => {
    if (questionType.columns) {
      const result = await commonSwalConfirm({
        title: `${column.cnt} 회차를 삭제하시겠습니까?`,
        confirmButtonText: "삭제",
        text: "등록된 기출문제도 삭제됩니다.",
      });

      if (result.isConfirmed) {
        const deleteResult = await Api.delete(
          `question_type_columns/${column.idx}`
        );
        if (deleteResult.status === 200) {
          updateModel({
            columns: questionType.columns.filter((c) => c !== column),
          });
        }
      }
    }
  };

  const updateColumn = (column: QuestionTypeColumn) => {
    if (questionType.columns) {
      Api.delete("question_type_columns");
      const columns = questionType.columns.map((c) => {
        if (c === column) c = column;
        return c;
      });
      updateModel({ columns: columns });
    }
  };

  return (
    <Row xs="5">
      <Form.Control type="hidden" value={column.idx ?? ""} />
      <Col md="auto" xs="4">
        <ReactDatePicker
          className="form-control"
          selected={column.date ? new Date(column.date) : new Date()}
          onChange={columnChangeHandler()}
          required
          dateFormat="Y-MM-dd"
          locale={ko}
        />
      </Col>
      <Col xs="auto" className="me-3">
        회차
      </Col>
      <Col xs="auto">
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            defaultValue={column.cnt}
            onChange={(e) => (column.cnt = parseInt(e.target.value))}
            style={{ width: "5rem" }}
            required
          />
        </InputGroup>
      </Col>
      <Col xs="auto">
        <Button variant="danger" className="ms-3" onClick={deleteHandler}>
          삭제
        </Button>
      </Col>
    </Row>
  );
};

export default QuestionTypeItemComponent;
