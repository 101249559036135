import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import MyContainer from "components/layouts/MyContainer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Term, TermColumn } from "model/types";
import { commonSave } from "assets/js/common";

export default function TermView() {
  const navigate = useNavigate();
  const [rows, setRows] = useState<TermColumn[]>([]);
  const [term, setTerm] = useState<Partial<Term>>({
    status: 0,
    required: 0,
  });
  const params = useParams();
  const { idx } = params;
  const isView = parseInt(idx as string) ? true : false;
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (isView) {
      Api.get(`/term_columns?parentIdx=${idx}`).then((response) =>
        setRows(response.data)
      );
      Api.get(`/terms/${idx}`).then((response) => setTerm(response.data));
    }
  }, [idx, isView]);

  useEffect(() => {
    console.log(rows);
  }, [rows]);

  const submitHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    if (e.target.checkValidity()) {
      commonSave({ send });
    }
    if (!isView) navigate(-1);
    return false;
  };

  const send: any = () => {
    if (isView) return Api.put(`/terms/${idx}`, term);
    else return Api.post(`/terms`, term).then(() => window.location.reload());
  };

  const updateModel = (fieldsToUpdate: Partial<Term>) => {
    setTerm({ ...term, ...fieldsToUpdate });
  };

  return (
    <MyContainer title={isView ? "약관 상세" : "약관 등록"}>
      <>
        <Form className="mb-3" validated={validated} onSubmit={submitHandler}>
          <Row>
            <Form.Group as={Col} controlId="title" className="mb-3">
              <Form.Label>제목</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                defaultValue={term.title}
                onChange={(e) => updateModel({ title: e.target.value })}
                required
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="title" className="mb-3" md="auto">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={term.status === 1}
                label="활성"
                onChange={(e) =>
                  updateModel({ status: e.target.checked ? 1 : 0 })
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="title" className="mb-3">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={term.required === 1}
                label="필수"
                onChange={(e) =>
                  updateModel({ required: e.target.checked ? 1 : 0 })
                }
              />
            </Form.Group>
          </Row>
          {isView && (
            <>
              <Row>
                <Col className="d-flex justify-content-end">
                  <Link to={`/help/terms/${idx}/columns`}>
                    <Button variant="primary" className="ms-3">
                      추가
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>버전</th>
                    <th>공개일</th>
                    <th>등록일</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.map((row: any, index) => (
                      <tr key={index}>
                        <td>
                          <Link to={`/help/terms/${idx}/columns/${row.idx}`}>
                            {index + 1}
                          </Link>
                        </td>
                        <td>{row.version}</td>
                        <td>{row.startDate}</td>
                        <td>{row.createdAt}</td>
                        <td>{row.status === 1 ? "활성" : "비활성"}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )}

          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                variant="danger"
                className="ms-3"
                onClick={() => navigate(-1)}
              >
                취소
              </Button>
              <Button variant="primary" className="ms-3" type="submit">
                저장
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    </MyContainer>
  );
}
