import { useEffect, useState } from "react";
import { Col, Image, Row, Table } from "react-bootstrap";
import MyContainer from "components/layouts/MyContainer";
import { Link } from "react-router-dom";
import MyPagination from "components/MyPagination";
import SelectFilter from "components/display/SelectFilter";
import ExcelBtn from "components/display/ExcelBtn";
import { commonSearch } from "assets/js/common";
import { DeclarationByReview, DeclarationType } from "model/Declaration";
import { Api } from "lib/axios/Api";
import FilterResetBtn from "components/display/FilterResetBtn";
import ImageIcon from "assets/images/icon/image.png";

export default function Declarations() {
  const [data, setData] = useState<any>();
  const [rows, setRows] = useState<DeclarationByReview[]>([]);
  const [declarationTypes, setDeclarationTypes] = useState<DeclarationType[]>(
    []
  );
  const search = commonSearch("/declarations", { setData, setRows });

  useEffect(() => {
    Api.get("declaration_types").then((response) => {
      setDeclarationTypes(response.data);
    });
    commonSearch("/declarations", { setData, setRows })();
  }, []);

  const ConvertReviewStatus = ({ row }: { row: DeclarationByReview }) => {
    switch (row.reviewStatus) {
      case 0:
        return <span className="text-success">대기</span>;
      case 1:
        return <span className="text-danger">블라인드</span>;
      case 2:
        return <span className="text-danger">경고</span>;
      case 3:
        return <span className="text-danger">블랙리스트</span>;
      default:
        return <span>ERROR</span>;
    }
  };

  const ConvertUserStatus = ({ row }: { row: DeclarationByReview }) => {
    switch (row.userStatus) {
      case 0:
        return <span className="text-success">일반</span>;
      case 1:
        return <span className="text-danger">경고({row.userWarningCnt})</span>;
      case 2:
        return <span className="text-danger">블랙리스트</span>;
      default:
        return <span>ERROR</span>;
    }
  };

  const ConvertDeclarationStatus = ({ row }: { row: DeclarationByReview }) => {
    switch (row.status) {
      case 0:
        return <span className="text-danger">미확인</span>;
      case 1:
        return <span className="text-success">확인</span>;
      default:
        return <span>ERROR</span>;
    }
  };

  return (
    <MyContainer title="신고 관리">
      <div>
        <div>
          <Row className="mb-3">
            <SelectFilter search={search} name="declarationTypeIdx">
              <option value="">유형</option>
              {declarationTypes.map((type) => (
                <option value={type.idx} key={type.idx}>
                  {type.title}
                </option>
              ))}
            </SelectFilter>
            <SelectFilter search={search} name="result">
              <option value="">리뷰 상태</option>
              <option value="0">정상</option>
              <option value="1">블라인드</option>
              <option value="2">경고</option>
              <option value="3">블랙리스트</option>
            </SelectFilter>
            <SelectFilter search={search} name="result">
              <option value="">열람</option>
              <option value="0">미확인</option>
              <option value="1">확인</option>
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
          <Row>
            <Col className="d-flex align-items-end justify-content-end mb-3">
              <ExcelBtn />
            </Col>
          </Row>
        </div>
        <Table>
          <colgroup>
            <col />
            <col />
            <col />
            <col width={"50px"} />
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>작성자</th>
              <th>내용</th>
              <th></th>
              <th>문화재</th>
              <th>회원 상태</th>
              <th>리뷰 상태</th>
              <th>열람</th>
              <th>신고 일시</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td key={row.idx}>
                  <Link to={`/service/reviews/${row.idx}`}>
                    {data?.number * data.size + index + 1}
                  </Link>
                </td>
                <td>{row.userNickName}</td>
                <td>{row.text}</td>
                <td>
                  {(row.reviewImageUrl1 ||
                    row.reviewImageUrl2 ||
                    row.reviewImageUrl3) && (
                    <Image src={ImageIcon} style={{ width: "1.5rem" }} />
                  )}
                </td>
                <td>{row.ccbaMnm1}</td>
                <td>{<ConvertUserStatus row={row} />}</td>
                <td>{<ConvertReviewStatus row={row} />}</td>
                <td>{<ConvertDeclarationStatus row={row} />}</td>
                <td>{row.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
}
