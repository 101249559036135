import { Api } from "lib/axios/Api";
import { CulturalAsset } from "model/CulturalAsset";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";

interface Props {
  culs: CulturalAsset[];
  setCuls: Function;
}

interface SearchItem {
  idx: number;
  ccbaMnm1: string;
}

const QuestionCul = (props: Props) => {
  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [isAdded, setIsAdded] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  let cols: any = [];

  const deleteClickHandler = (cul: CulturalAsset) => {
    props.setCuls(props.culs.filter((c) => c !== cul));
  };

  // 리스트 생성
  if (props.culs)
    props.culs.forEach((cul, i) => {
      cols.push(
        <Row key={i} className="my-2">
          <Col md="1">{i + 1}</Col>
          <Col className="border text-center pt-2">
            {`${cul.ccbaAsno} / ${cul.ccbaMnm1} / ${cul.ccceName ?? "-"} / ${
              cul.ccbaCtcdNm
            }`}
          </Col>
          <Col md="auto">
            <Button variant="danger" onClick={() => deleteClickHandler(cul)}>
              삭제
            </Button>
          </Col>
        </Row>
      );
    });

  const searchHandler = () => {
    if (!searchRef.current) return;

    const searchText = searchRef.current.value.trim();

    if (searchText) {
      setTimeout(() => {
        if (searchText !== searchRef.current?.value.trim()) return;

        Api.get(`/cultural_assets/search_lite`, {
          params: { name: searchText },
        }).then((response) => {
          setSearchResults(response.data);
        });
      }, 500);
    } else {
      setSearchResults([]);
    }
  };

  const listClickHandler = (e: any, ca: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.culs) {
      if (props.culs.filter((cul) => cul.idx === ca.idx).length > 0) {
        toast.error("이미 등록된 문화재입니다.", { autoClose: 1000 });
      } else {
        props.setCuls([...props.culs, ca]);
        setSearchResults([]);
        setIsAdded(false);
      }
    }
    return false;
  };

  return (
    <>
      <Row className="mb-3">
        <Col md="10">
          <Row>
            <Form.Label column sm="4">
              관련 문화재 등록
            </Form.Label>
          </Row>
          <Row>
            <Col>
              {cols}
              {!isAdded && (
                <Row>
                  <Col className="text-center">
                    <Button variant="success" onClick={() => setIsAdded(true)}>
                      추가
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {isAdded && (
            <div>
              <Row>
                <Col md="1">{(props.culs?.length ?? 0) + 1}</Col>
                <Col className="ps-0">
                  <Form.Control
                    defaultValue={""}
                    onChange={(e) => searchHandler()}
                    ref={searchRef}
                  />

                  <div>
                    <ListGroup style={{ maxHeight: "200px", overflow: "auto" }}>
                      {searchResults.map((result) => (
                        <ListGroup.Item
                          key={result.idx}
                          action
                          onClick={(e) => listClickHandler(e, result)}
                        >
                          {result.ccbaMnm1}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default QuestionCul;
