import { Api } from 'lib/axios/Api';
import MyContainer from 'components/layouts/MyContainer';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { DeclarationType } from 'model/Declaration';
import { appSuccessMsg, commonSwalConfirm } from 'assets/js/common';

export default function DeclarationTypes() {
  const [ rows, setRows ] = useState<DeclarationType[]>([]);
  const [ newDeclarationType, setNewDeclarationType ] = useState<Partial<DeclarationType>>({status: 0})

  useEffect(() => {
    Api.get("/declaration_types").then((response) => {
      setRows(response.data); 
    });
  }, []);
  
  const addClickHandler = async () => {
    if(newDeclarationType.title) {
      const result = await commonSwalConfirm({title: "추가하시겠습니까?", confirmButtonText: "추가"});
      if(result.isConfirmed) {
        Api.post("/declaration_types", newDeclarationType).then((response) => {
          appSuccessMsg({msg: "추가되었습니다."});
          setRows([response.data].concat(rows));
          setNewDeclarationType({});
        });
      }
    }
  }

  return (
    <MyContainer title="신고 유형 관리">
      <div>
        <Row>
          <Col md="auto">
            <InputGroup className="mb-3">
              <Form.Control 
                style={{width: '25rem'}} 
                value={newDeclarationType.title ?? ""}
                onChange={(e) => setNewDeclarationType((type) => {return {...type, title: e.target.value}})}
                maxLength={30}
              />
              <Button variant="success" onClick={addClickHandler}>추가</Button>
            </InputGroup>
          </Col>
        </Row>
        <hr/>
        <Row>
          {rows.map((row: any) => (
            <InquiryRow key={row.idx} row={row} setRows={setRows}/>
          ))}
        </Row>
      </div>
    </MyContainer>
  );

  function InquiryRow({row, setRows}: {row: DeclarationType, setRows: Function}) {
    const [ declarationType, setDeclarationType ] = useState<DeclarationType>(row);

    const modifyClickHandler = async () => {
      const result = await commonSwalConfirm({title: "수정하시겠습니까?", confirmButtonText: "수정"});
      if(result.isConfirmed) {
        Api.put(`/declaration_types/${row.idx}`, declarationType).then((response) => {
          setRows((rows: DeclarationType[]) => rows.map((r) => r.idx === declarationType.idx ? declarationType : r));
          appSuccessMsg({msg: "수정되었습니다."});
        });
      }
    }

    const deleteClickHandler = async () => {
      const result = await commonSwalConfirm({title: "삭제하시겠습니까?", confirmButtonText: "삭제"});
      if(result.isConfirmed) {
        Api.delete(`/declaration_types/${row.idx}`).then(() => {
          setRows((rows: DeclarationType[]) => rows.filter((r) => r.idx !== declarationType.idx));
          appSuccessMsg({msg: "삭제되었습니다."});
        });
      }
    }

    return (
      <Row>
        <Col md="auto">
          <Form>
            <InputGroup className="mb-3">
              <div style={{position: 'relative'}}>
                <Form.Control 
                  style={{width: '25rem', paddingRight: "3rem"}} 
                  defaultValue={row.title} 
                  maxLength={55}
                  onChange={(e) => setDeclarationType({...declarationType, title: e.target.value})}
                />
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  style={{position: 'absolute', right: 0, top: '.5rem'}}
                  defaultChecked={row.status === 1}
                  onChange={(e) => setDeclarationType({...declarationType, status: e.target.checked ? 1 : 0})}
                />
              </div>
              <Button variant="primary" onClick={modifyClickHandler}>수정</Button>
              <Button variant="danger" onClick={deleteClickHandler}>삭제</Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    )
  }
}
