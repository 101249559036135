import { commonSearch } from "assets/js/common";
import MyPagination from "components/MyPagination";
import ExcelBtn from "components/display/ExcelBtn";
import FilterResetBtn from "components/display/FilterResetBtn";
import InputFilter from "components/display/InputFilter";
import SelectFilter from "components/display/SelectFilter";
import MyContainer from "components/layouts/MyContainer";
import { User } from "model/User";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function Users() {
  const [data, setData] = useState<any>();
  const [rows, setRows] = useState<User[]>([]);
  const search = commonSearch("/users", { setData, setRows });
  useEffect(() => commonSearch("/users", { setData, setRows })(), []);

  const convertGender = (user: User) => {
    switch (user.gender) {
      case 0:
        return "비공개";
      case 1:
        return "남성";
      case 2:
        return "여성";
    }
  };

  const convertStatus = (user: User) => {
    switch (user.status) {
      case 0:
        return "일반";
      case 1:
        return "경고";
      case 2:
        return "블랙리스트";
      case 9:
        return "탈퇴";
    }
  };

  return (
    <MyContainer title="회원 관리">
      <div>
        <div>
          <Row className="mb-3">
            <SelectFilter search={search} name="status">
              <option value="">상태</option>
              <option value="0">일반</option>
              <option value="1">경고</option>
              <option value="2">블랙리스트</option>
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
          <InputFilter search={search}>
            <option value="email">이메일</option>
            <option value="nickName">닉네임</option>
          </InputFilter>
          <Row>
            <Col className="d-flex align-items-end justify-content-end mb-3">
              <ExcelBtn />
            </Col>
          </Row>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>이메일(아이디)</th>
              <th>가입 경로</th>
              <th>닉네임</th>
              <th>성별</th>
              <th>리뷰 수</th>
              <th>상태</th>
              <th>블랙 사유</th>
              <th>가입일자</th>
              <th>마지막 로그인 일자</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td key={row.idx}>
                  <Link to={`./${row.idx}`}>
                    {data?.number * data.size + index + 1}
                  </Link>
                </td>
                <td>{row.email}</td>
                <td>{row.snsType}</td>
                <td>{row.nickName}</td>
                <td>{convertGender(row)}</td>
                <td>{row.reviewCnt}</td>
                <td>{convertStatus(row)}</td>
                <td>{row.blackMemo ?? "-"}</td>
                <td>{row.createdAt}</td>
                <td>{row.lastLoginAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
}

export default Users;
