import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import store from "store/store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Admin from "Admin";
import MyToast from "assets/js/MyToast";
import ImageViewer from "pages/ImageViewer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
document.documentElement.lang = "ko";
MyToast.addDefaultOptions({ position: "top-center" });
root.render(
  <Provider store={store}>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />

    <BrowserRouter>
      <Routes>
        <Route path="/image/:imageUrl" element={<ImageViewer />} />
        <Route path="*" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
