import { commonSearch } from "assets/js/common";
import MyPagination from "components/MyPagination";
import FilterResetBtn from "components/display/FilterResetBtn";
import InputFilter from "components/display/InputFilter";
import SelectFilter from "components/display/SelectFilter";
import MyContainer from "components/layouts/MyContainer";
import { Objection } from "model/Objection";
import { useEffect, useState } from "react";
import { Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const Objections = () => {
  const [rows, setRows] = useState<Objection[]>([]);
  const [data, setData] = useState<any>();

  const search = commonSearch("/objections", { setData, setRows });
  useEffect(() => commonSearch("/objections", { setData, setRows })(), []);

  const convertStatus = (status: number) => {
    switch (status) {
      case 0:
        return "대기";
      case 1:
        return "인정";
      case 2:
        return "무효";
    }
    return "";
  };

  const convertUserStatusLog = (status: number) => {
    switch (status) {
      case 0:
        return "일반";
      case 1:
        return "블라인드";
      case 2:
        return "경고";
      case 3:
        return "블랙리스트";
    }
    return "";
  };
  return (
    <MyContainer title="이의신청 관리">
      <div>
        <div>
          <Row className="mb-3">
            <SelectFilter search={search} name="status">
              <option value="">상태</option>
              <option value="0">대기</option>
              <option value="1">인정</option>
              <option value="2">무효</option>
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
          <InputFilter search={search}>
            <option value="userEmail">이메일</option>
            <option value="culName">문화재명</option>
          </InputFilter>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>이메일</th>
              <th>닉네임</th>
              <th>상태</th>
              <th>사유</th>
              <th>등록일</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows?.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`./${row.idx}`}>
                      {data?.number * data.size + index + 1}
                    </Link>
                  </td>
                  <td>{row.user?.email ?? "-"}</td>
                  <td>{row.user?.nickName ?? "-"}</td>
                  <td>{convertUserStatusLog(row.userStatusLog.status)}</td>
                  <td>{row.userStatusLog.reason}</td>
                  <td>{row.userStatusLog.createdAt}</td>
                  <td>{convertStatus(row.status)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
};

export default Objections;
