import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { useNavigate } from "react-router-dom";
import { QuestionType, QuestionTypeColumn } from "model/Question";
import MyContainer from "components/layouts/MyContainer";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { commonSave } from "assets/js/common";

function QuestionTypeCreate() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [questionType, setQuestionType] = useState<Partial<QuestionType>>({
    columns: [],
  });

  const addHandler = () => {
    let newColumn: Partial<QuestionTypeColumn> = {
      date: new Date().toISOString().split("T")[0],
    };
    if (questionType.columns) {
      updateModel({
        columns: [...questionType.columns, newColumn as QuestionTypeColumn],
      });
    }
  };

  const columnChangeHandler = (idx: any) => (date: any) => {
    if (questionType.columns) {
      let newArr = [...questionType.columns];
      newArr[idx].date = date.toISOString().split("T")[0];
      updateModel({ columns: newArr });
    }
  };

  const deleteHandler = (thisColumn: QuestionTypeColumn) => {
    if (questionType.columns) {
      updateModel({
        columns: questionType.columns.filter((column) => column !== thisColumn),
      });
    }
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.checkValidity()) {
      commonSave({ send });
    }
    setValidated(true);
  };

  const send: any = () => {
    return Api.post(`/question_types`, questionType).then((response) => {
      navigate(`/admin/question_types/${response.data.idx}`, { replace: true });
    });
  };

  const updateModel = (fieldsToUpdate: Partial<QuestionType>) => {
    if (questionType) setQuestionType({ ...questionType, ...fieldsToUpdate });
  };

  return (
    <MyContainer title={"기출 출제일 등록"}>
      <Form validated={validated} onSubmit={submitHandler}>
        <Row>
          <Row className="mb-3" xs="3">
            <Form.Control
              type="hidden"
              name="idx"
              value={questionType.idx ?? ""}
            />
            <Form.Group as={Col} className="mb-3">
              <Form.Label>기출시험 종류</Form.Label>
              <Form.Control
                type="text"
                placeholder="직접입력"
                defaultValue={questionType.name}
                onChange={(e) => updateModel({ name: e.target.value })}
                required
                maxLength={21}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Col md="auto" className="me-3">
              출제 일
            </Col>
            <Col>
              {questionType.columns?.map((column: any, n) => (
                <Row key={n} xs="5">
                  <Form.Control type="hidden" value={""} />
                  <Col md="auto" xs="4">
                    <ReactDatePicker
                      className="form-control"
                      selected={
                        column.date ? new Date(column.date) : new Date()
                      }
                      onChange={columnChangeHandler(n)}
                      required
                      dateFormat="Y-MM-dd"
                      locale={ko}
                    />
                  </Col>
                  <Col xs="auto" className="me-3">
                    회차
                  </Col>
                  <Col xs="auto">
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        defaultValue={column.cnt}
                        onChange={(e) =>
                          (column.cnt = parseInt(e.target.value))
                        }
                        style={{ width: "5rem" }}
                        required
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="danger"
                      className="ms-3"
                      onClick={() => deleteHandler(column)}
                      data-n={n}
                    >
                      삭제
                    </Button>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="primary" className="ms-3" onClick={addHandler}>
                출제일 회차 추가
              </Button>
            </Col>
          </Row>
          <Col className="d-flex justify-content-end">
            <Button
              variant="danger"
              className="ms-3"
              onClick={() => navigate(-1)}
            >
              취소
            </Button>
            <Button type="submit" variant="primary" className="ms-3">
              저장
            </Button>
          </Col>
        </Row>
      </Form>
    </MyContainer>
  );
}

export default QuestionTypeCreate;
