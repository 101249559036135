import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";

const ImageViewer = () => {
  const { imageUrl } = useParams() as { imageUrl: string };
  return (
    <div className="w-100 h-100 d-flex">
      <Image src={imageUrl} className="m-auto" />;
    </div>
  );
};

export default ImageViewer;
