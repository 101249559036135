import { logout } from "features/authSlice";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { NavLink, useLocation } from "react-router-dom";
import { isSuperAdmin } from "assets/js/common";

function MyNav() {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <Navbar key={"sm"} bg="dark" expand={"sm"} variant="dark" className="mb-5">
      <Container>
        <Navbar.Brand href="/">Home</Navbar.Brand>
        <Nav className="me-auto" activeKey={location.pathname}>
          <Nav.Link as={NavLink} to={"/"}>
            대시보드
          </Nav.Link>
          {isSuperAdmin(auth.user.role) && (
            <NavDropdown title="어드민 관리" id="nav-dropdown">
              <NavDropdown.Item as={NavLink} to={"/admin/admins"}>
                어드민 계정 관리
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/admin/permissions"}>
                권한 관리
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/admin/question_types"}>
                기출 시험 유형 관리
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/admin/declaration_types"}>
                신고 유형 관리
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to={"/admin/inquiry_types"}>
                문의 유형 관리
              </NavDropdown.Item>
            </NavDropdown>
          )}

          <NavDropdown title="서비스 관리" id="nav-dropdown">
            <NavDropdown.Item as={NavLink} to={"/service/cultural_assets"}>
              문화재 관리
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={"/service/users"}>
              회원 관리
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={"/service/declarations"}>
              신고 관리
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={"/service/questions"}>
              기출 관리
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={"/service/reviews"}>
              리뷰 관리
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={"/service/objections"}>
              이의 신청 관리
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="고객센터" id="nav-dropdown">
            <NavDropdown.Item as={NavLink} to={"/help/notices"}>
              공지사항 관리
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={"/help/faqs"}>
              자주묻는 질문 관리
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={"/help/inquiries"}>
              문의 관리
            </NavDropdown.Item>
            {isSuperAdmin(auth.user.role) && (
              <NavDropdown.Item as={NavLink} to={"/help/terms"}>
                약관 관리
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </Nav>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text onClick={() => dispatch(logout())}>
            Signed in as: <span className="text-white">{auth.user.id}</span>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNav;
