import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { useNavigate, useParams } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import { Question, QuestionType, QuestionTypeColumn } from "model/Question";
import Swal from "sweetalert2";
import QuestionCul from "./QuestionCul";
import { appSuccessMsg, commonSave } from "assets/js/common";
import { CulturalAsset } from "model/CulturalAsset";

const QuestionView = () => {
  const navigate = useNavigate();
  const { idx } = useParams();
  const isView = parseInt(idx as string) ? true : false;
  const inputFile: any = useRef(null);
  const [question, setQuestion] = useState<Partial<Question>>({ culs: [] });
  const [types, setTypes] = useState<QuestionType[]>([]);

  useEffect(() => {
    Api.get(`/question_types/active`).then((response) => {
      setTypes(response?.data);
    });
    if (isView) {
      Api.get(`/questions/${idx}`).then((response) =>
        setQuestion(response.data)
      );
    }
  }, [idx, isView]);

  useEffect(() => {
    if (types.length) {
      updateModel({
        questionType: types[0],
        questionTypeColumn: (types[0].columns as QuestionTypeColumn[])[0],
        typeColumnIdx: (types[0].columns as QuestionTypeColumn[])[0].idx,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  // 이미지 첨부
  const imgButtonHandler = (e: any) => {
    Swal.fire({
      title: "이미지를 첨부하시겠습니까?",
      text: "이미지를 첨부하시면 입력된 텍스트가 삭제됩니다.",
      showDenyButton: true,
      confirmButtonText: "첨부하기",
      denyButtonText: "취소",
    }).then((result) => {
      if (!result.isConfirmed) return;
      if (inputFile.current) inputFile.current.click();
    });
  };

  const imgChangeHandler = (e: any) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    return Api.post(`/questions/save_image`, formData).then((response) =>
      updateModel({ contentImg: response.data })
    );
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.checkValidity()) {
      commonSave({ send });
    }
  };

  const send: any = () => {
    if (isView)
      return Api.put(`/questions/${idx}`, question, {
        headers: { "Content-Type": "application/json" },
      });
    else
      return Api.post(`/questions`, question).then(() =>
        navigate("/service/questions")
      );
  };

  const changeQuestionType = (idx: number) => {
    const selectType = types.filter((type) => type.idx === idx)[0];
    updateModel({
      questionType: selectType,
      typeColumnIdx: (selectType.columns as QuestionTypeColumn[])[0].idx,
    });
  };

  const changeQuestionColumn = (idx: number) => {
    const selectColumn = question.questionType?.columns?.filter(
      (column) => column.idx === idx
    )[0];
    updateModel({
      questionTypeColumn: selectColumn,
      typeColumnIdx: selectColumn?.idx,
    });
  };

  function updateModel(fieldsToUpdate: Partial<Question>) {
    if (question) setQuestion({ ...question, ...fieldsToUpdate });
  }

  const deleteClickHandler = () => {
    Swal.fire({
      title: "삭제하시겠습니까?",
      showDenyButton: true,
      confirmButtonText: "삭제",
      denyButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete(`/questions/${idx}`).then((response) => {
          appSuccessMsg({ msg: "삭제되었습니다." });
          navigate(-1);
        });
      }
    });
  };
  if (!types.length) return <></>;
  return (
    <MyContainer title={isView ? "기출 문제 상세" : "기출 문제 등록"}>
      <Form onSubmit={submitHandler}>
        <Row className="text-truncate">
          <Col md="auto">
            <Form.Group as={Row} className="mb-3" controlId="type">
              <Form.Label column sm="auto">
                기출문제 종류
              </Form.Label>
              <Col sm="auto">
                <Form.Select
                  value={question.questionType?.idx ?? types[0].idx}
                  onChange={(e) => changeQuestionType(parseInt(e.target.value))}
                >
                  {types.map((type, i) => (
                    <option value={type.idx} key={i}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col md="auto">
            <Form.Group as={Row} className="mb-3 mx-3" controlId="cnt">
              <Form.Label column sm="auto">
                기출 출제 회차
              </Form.Label>
              <Col sm="auto">
                <Form.Select
                  value={question.typeColumnIdx ?? ""}
                  onChange={(e) =>
                    changeQuestionColumn(parseInt(e.target.value))
                  }
                >
                  {question.questionType?.columns?.map(
                    (column: QuestionTypeColumn) => (
                      <option value={column.idx} key={column.idx}>
                        {column.cnt}
                      </option>
                    )
                  )}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col md="auto">
            <Form.Group as={Row} className="mb-3" controlId="level">
              <Form.Label column sm="auto">
                LEVEL
              </Form.Label>
              <Col sm="auto">
                <Form.Select
                  value={question.questionTypeLevelIdx ?? ""}
                  onChange={(e) =>
                    updateModel({
                      questionTypeLevelIdx: e.target.value
                        ? parseInt(e.target.value)
                        : undefined,
                    })
                  }
                >
                  <option value={""}>없음</option>
                  {question.questionType?.questionTypeLevels.map((level, i) => (
                    <option value={level.idx} key={i}>
                      {level.text}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="10">
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column md="2">
                기출 출제 일
              </Form.Label>
              <Col sm="auto">
                <Form.Group
                  as={Row}
                  controlId="name"
                  className="mb-3"
                  sm="auto"
                >
                  <Col>
                    <Form.Control
                      defaultValue={
                        question.questionTypeColumn?.date.split("-")[0] ?? ""
                      }
                      readOnly
                    />
                  </Col>
                  <Form.Label className="mt-1">년도</Form.Label>
                </Form.Group>
              </Col>

              <Col sm="auto">
                <Form.Group
                  as={Row}
                  controlId="name"
                  className="mb-3"
                  sm="auto"
                >
                  <Col>
                    <Form.Control
                      defaultValue={
                        question.questionTypeColumn?.date.split("-")[1] ?? ""
                      }
                      readOnly
                    />
                  </Col>
                  <Form.Label className="mt-1">월</Form.Label>
                </Form.Group>
              </Col>
              <Col sm="auto">
                <Form.Group
                  as={Row}
                  controlId="name"
                  className="mb-3"
                  sm="auto"
                >
                  <Col>
                    <Form.Control
                      defaultValue={
                        question.questionTypeColumn?.date.split("-")[2] ?? ""
                      }
                      readOnly
                    />
                  </Col>
                  <Form.Label className="mt-1">일</Form.Label>
                </Form.Group>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="auto" md="10">
            <Form.Group as={Row} controlId="name" className="mb-3">
              <Col md="2">
                <Form.Label className="mt-1">문제</Form.Label>
              </Col>
              <Col md="auto">
                <Button
                  variant="primary"
                  onClick={(e) => imgButtonHandler(e)}
                  className="mb-3"
                >
                  사진 첨부
                </Button>
              </Col>
              {question?.contentImg && (
                <Col md="auto">
                  <Button
                    variant="danger"
                    onClick={(e) => updateModel({ contentImg: null })}
                  >
                    사진 삭제
                  </Button>
                </Col>
              )}
              <Col className="mb-3">
                {question?.contentImg ? (
                  <Image
                    src={process.env.REACT_APP_API_URL + question.contentImg}
                    style={{ maxWidth: "100%", maxHeight: "240px" }}
                    className="border"
                  />
                ) : (
                  <Form.Control
                    as="textarea"
                    rows={5}
                    defaultValue={question?.content}
                    onChange={(e) => updateModel({ content: e.target.value })}
                    required
                  />
                )}
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between" md="10">
            <Form.Group as={Row} className="mb-3 me-3">
              <Form.Label column md="4">
                번호
              </Form.Label>
              <Col>
                <Form.Control
                  defaultValue={question?.number}
                  onChange={(e) =>
                    updateModel({ number: parseInt(e.target.value) })
                  }
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="4">
                정답
              </Form.Label>
              <Col>
                <Form.Control
                  defaultValue={question?.answer}
                  onChange={(e) => updateModel({ answer: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        {isView && (
          <QuestionCul
            culs={question.culs ?? []}
            setCuls={(culs: CulturalAsset[]) =>
              updateModel({ ...question, culs })
            }
          />
        )}
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              variant="danger"
              className="ms-3"
              onClick={deleteClickHandler}
            >
              삭제
            </Button>
            <Button
              variant="danger"
              className="ms-3"
              onClick={() => navigate(-1)}
            >
              취소
            </Button>
            <Button variant="primary" className="ms-3" type="submit">
              저장
            </Button>
          </Col>
        </Row>
        <input
          type="file"
          ref={inputFile}
          style={{ display: "none" }}
          accept="image/png, image/jpeg"
          onChange={(e) => imgChangeHandler(e)}
        />
      </Form>
    </MyContainer>
  );
};

export default QuestionView;
