import { useEffect, useState } from "react";
import { Image, Row, Table } from "react-bootstrap";
import MyContainer from "components/layouts/MyContainer";
import { Link } from "react-router-dom";
import { Inquiry, InquiryType } from "model/types";
import SelectFilter from "components/display/SelectFilter";
import InputFilter from "components/display/InputFilter";
import MyPagination from "components/MyPagination";
import { commonSearch, getUserNickName } from "assets/js/common";
import { Api } from "lib/axios/Api";
import FilterResetBtn from "components/display/FilterResetBtn";
import ImageIcon from "assets/images/icon/image.png";

export default function Inquiries() {
  const [data, setData] = useState<any>();
  const [rows, setRows] = useState<Inquiry[]>([]);
  const [inquiryTypes, setInquiryTypes] = useState<InquiryType[]>([]);

  const search = commonSearch("/inquiries", { setData, setRows });
  useEffect(() => {
    commonSearch("/inquiries", { setData, setRows })();
    Api.get("inquiry_types/active").then((response) =>
      setInquiryTypes(response.data)
    );
  }, []);

  return (
    <MyContainer title="문의 관리">
      <div>
        <div>
          <Row className="mb-3">
            <SelectFilter search={search} name="type">
              <option value="">문의 유형</option>
              {inquiryTypes.map((inquiryType) => (
                <option value={inquiryType.title} key={inquiryType.idx}>
                  {inquiryType.title}
                </option>
              ))}
            </SelectFilter>
            <SelectFilter search={search} name="status">
              <option value="">상태</option>
              <option value="0">답변대기</option>
              <option value="1">답변완료</option>
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
          <InputFilter search={search}>
            <option value="nickName">닉네임</option>
            <option value="email">이메일</option>
            <option value="title">제목</option>
          </InputFilter>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>문의 유형</th>
              <th>제목</th>
              <th></th>
              <th>닉네임</th>
              <th>이메일</th>
              <th>날짜</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows?.map((row, index) => (
                <tr key={index}>
                  <td key={row.idx}>
                    <Link to={`./${row.idx}`}>
                      {data?.number * data.size + index + 1}
                    </Link>
                  </td>
                  <td>{row.type}</td>
                  <td className="text-truncate" style={{ maxWidth: "15rem" }}>
                    {row.title}
                  </td>
                  <td>
                    {row.imageUrl && (
                      <Image src={ImageIcon} style={{ width: "1.5rem" }} />
                    )}
                  </td>
                  <td>{getUserNickName(row.user)}</td>
                  <td>{row.user?.email}</td>
                  <td>{row.createdAt}</td>
                  <td>{row.status === 1 ? "답변완료" : "답변대기"}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
}
