import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { User } from "model/User";
import { Review } from "model/Review";
import { Inquiry } from "model/types";
import { Link, useNavigate } from "react-router-dom";
import { getUserNickName } from "assets/js/common";

function Dashboard() {
  const [counts, setCounts] = useState<any>();
  const [users, setUsers] = useState<User[]>([]);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [inquiries, setInquiries] = useState<Inquiry[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    Api.get("/dashboard").then((response) => setCounts(response.data));
    Api.get("/users", { params: { page: 0, size: 5 } }).then((response) =>
      setUsers(response.data.content)
    );
    Api.get("/reviews", { params: { page: 0, size: 5 } }).then((response) =>
      setReviews(response.data.content)
    );
    Api.get("/inquiries", { params: { page: 0, size: 5 } }).then((response) =>
      setInquiries(response.data.content)
    );
  }, []);

  return (
    <Container fluid>
      <Row className="mb-5 justify-content-center" xl="2">
        <Card
          bg="primary"
          style={{ width: "18rem", opacity: 0.85 }}
          text="white"
          className="m-4"
        >
          <Card.Header>신규 회원</Card.Header>
          <Card.Body>
            <Card.Title className="text-center fs-3">{`${counts?.user.toDay} / ${counts?.user.total}`}</Card.Title>
          </Card.Body>
        </Card>
        <Card
          bg="secondary"
          style={{ width: "18rem", opacity: 0.85 }}
          text="white"
          className="m-4"
        >
          <Card.Header>신규 리뷰</Card.Header>
          <Card.Body>
            <Card.Title className="text-center fs-3">{`${counts?.review.toDay} / ${counts?.review.total}`}</Card.Title>
          </Card.Body>
        </Card>
        <Card
          bg="success"
          style={{ width: "18rem", opacity: 0.85 }}
          text="white"
          className="m-4"
        >
          <Card.Header>문의 사항</Card.Header>
          <Card.Body>
            <Card.Title className="text-center fs-3">{`${counts?.inquiry.toDay} / ${counts?.inquiry.total}`}</Card.Title>
          </Card.Body>
        </Card>
      </Row>
      <Row xl="2" md="1" className="mb-5">
        <Col className="mb-5">
          <Row className="flex-column">
            <Col>
              <Link
                to="/service/users"
                className="fr text-muted text-decoration-none"
              >
                더보기
              </Link>
              <h3>회원 현황</h3>
            </Col>
            <Col>
              <Table striped bordered hover className="d-table text-break">
                <thead>
                  <tr>
                    <td>이메일</td>
                    <td>닉네임</td>
                    <td>가입일</td>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr
                      key={user.idx}
                      onClick={() => navigate(`/service/users/${user.idx}`)}
                    >
                      <td>{user.email}</td>
                      <td>{user.nickName}</td>
                      <td>{user.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="flex-column">
            <Col>
              <Link
                to="/service/reviews"
                className="fr text-muted text-decoration-none"
              >
                더보기
              </Link>
              <h3>신규 리뷰</h3>
            </Col>
            <Col>
              <Table striped bordered hover className="d-table">
                <thead>
                  <tr>
                    <td>이메일</td>
                    <td>닉네임</td>
                    <td>리뷰 내용</td>
                    <td>리뷰 등록일</td>
                  </tr>
                </thead>
                <tbody>
                  {reviews.map((review) => (
                    <tr
                      key={review.idx}
                      onClick={() => navigate(`/service/reviews/${review.idx}`)}
                    >
                      <td>{review.user?.email}</td>
                      <td>{getUserNickName(review.user)}</td>
                      <td
                        className="text-truncate"
                        style={{ maxWidth: "10rem" }}
                      >
                        {review.reviewDetails[0].text}
                      </td>
                      <td>{review.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="flex-column">
            <Col>
              <Link
                to="/help/inquiries"
                className="fr text-muted text-decoration-none"
              >
                더보기
              </Link>
              <h3>문의 사항</h3>
            </Col>
            <Col>
              <Table striped bordered hover className="d-table">
                <thead>
                  <tr>
                    <td>이메일</td>
                    <td>문의 종류</td>
                    <td>문의 제목</td>
                    <td>문의 작성일</td>
                    <td>상태</td>
                  </tr>
                </thead>
                <tbody>
                  {inquiries.map((inquiry) => (
                    <tr
                      key={inquiry.idx}
                      onClick={() => navigate(`/help/inquiries/${inquiry.idx}`)}
                    >
                      <td>{inquiry.user?.email}</td>
                      <td>{inquiry.type}</td>
                      <td
                        className="text-truncate"
                        style={{ maxWidth: "20rem" }}
                      >
                        {inquiry.title}
                      </td>
                      <td>{inquiry.createdAt}</td>
                      <td>
                        {inquiry.status === 0 ? "답변 대기" : "답변 완료"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
