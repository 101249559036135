import { commonSwalConfirm } from "assets/js/common";
import { Api } from "lib/axios/Api";
import { CulImage } from "model/CulturalAsset";
import { useEffect, useRef, useState } from "react";
import { Button, CloseButton, Col, Form, Row } from "react-bootstrap";

interface Props {
  culIdx: string;
}
const CulImages = ({ culIdx }: Props) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [culImages, setCulImages] = useState<CulImage[]>([]);

  useEffect(() => {
    Api.get(`cultural_asset_images/${culIdx}`).then((response) =>
      setCulImages(response.data)
    );
  }, []);

  const imgAddHandler = () => {
    if (inputFile.current) inputFile.current.click();
  };

  const imgChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const formData = new FormData();
    formData.append("upload", e.target.files[0]);
    e.target.value = "";
    formData.append("culIdx", culIdx);
    Api.post(`/cultural_asset_images`, formData).then((response) => {
      setCulImages((arr) => [...arr, response.data]);
    });
  };

  const deleteImage = async (idx: number) => {
    const result = await commonSwalConfirm({
      title: "정말 삭제하시겠습니까?",
      confirmButtonText: "삭제",
    });
    if (result.isConfirmed)
      setCulImages((arr) => arr.filter((culImage) => culImage.idx !== idx));
  };
  return (
    <Row>
      <Col className="mb-3">
        <Form.Label>추가 사진</Form.Label>
        <Row className="w-100 border m-0 d-flex p-3">
          {culImages.map((culImage) => (
            <CustomImage
              culImage={culImage}
              deleteImage={deleteImage}
              key={culImage.idx}
            />
          ))}
          <Col xs="3" className="text-center p-auto d-flex">
            <Button
              variant="outline-primary"
              className="fs-3  m-auto rounded-circle"
              style={{ padding: ".2rem 1rem .5rem 1rem" }}
              onClick={imgAddHandler}
            >
              +
            </Button>

            <input
              type="file"
              ref={inputFile}
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onChange={(e) => imgChangeHandler(e)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const CustomImage = ({
  culImage,
  deleteImage,
}: {
  culImage: CulImage;
  deleteImage: Function;
}) => {
  const deleteClickHandler = async () => {
    const result = await commonSwalConfirm({
      title: "이미지를 삭제하시겠습니까?",
      confirmButtonText: "삭제",
    });
    if (result.isConfirmed) {
      Api.delete(`/cultural_asset_images/${culImage.idx}`).then(() =>
        deleteImage(culImage.idx)
      );
    }
  };
  return (
    <Col className="p-1 me-3 border" style={{ position: "relative" }} xs="3">
      <a
        style={{
          height: "10rem",
          backgroundImage: `url(${
            process.env.REACT_APP_IMAGE_URL + culImage.imageUrl
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          position: "relative",
          display: "block",
        }}
        href={process.env.REACT_APP_IMAGE_URL + culImage.imageUrl}
        target="_blank"
      />
      <CloseButton
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        onClick={deleteClickHandler}
      />
    </Col>
  );
};

export default CulImages;
