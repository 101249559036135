import { toast } from "react-toastify";

class Toast {
  public defaultOptions = {
    autoClose: 1000,
    pauseOnFocusLoss: false,
  };

  public error = (content: string, options?: {} | undefined) => {
    toast.error(content, { ...this.defaultOptions, ...options });
  };

  public addDefaultOptions = (options: {}) => {
    this.defaultOptions = { ...this.defaultOptions, ...options };
  };
}

const MyToast = new Toast();
export default MyToast;
