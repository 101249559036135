import { Declaration } from "model/Declaration";
import { Table } from "react-bootstrap";

const DeclarationModal = ({
  declarations,
}: {
  declarations: Declaration[];
}) => {
  return (
    <div>
      <h5 className="fw-bold">신고 내역</h5>
      <div>
        <Table>
          <thead>
            <tr className="text-center text-truncate">
              <th>유형</th>
              <th>신고자</th>
              <th>신고일</th>
              <th>확인</th>
            </tr>
          </thead>
          <tbody>
            {declarations.map((declaration) => (
              <tr key={declaration.idx}>
                <td>{declaration.declarationType.title}</td>
                <td className="text-truncate">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/service/users/${declaration.user.idx}`}
                  >
                    {declaration.user.nickName}
                  </a>
                </td>
                <td>{declaration.createdAt}</td>
                <td>{declaration.status === 0 ? "❌" : "✔️"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DeclarationModal;
