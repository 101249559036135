import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import MyContainer from "components/layouts/MyContainer";
import { Link } from "react-router-dom";
import { Term } from "model/types";
import RegistBtn from "components/display/RegistBtn";
import { commonSearch } from "assets/js/common";
import TermSort from "./TermsSort";

export default function Terms() {
  const [rows, setRows] = useState<Term[]>([]);
  const [data, setData] = useState<any>();
  const [show, setShow] = useState(false);

  const search = commonSearch("/terms", { setData, setRows });
  useEffect(() => commonSearch("/terms", { setData, setRows })(), []);

  return (
    <MyContainer title="약관 관리">
      <div>
        <div>
          <Row>
            <Col className="d-flex align-items-end justify-content-end mb-3">
              <Button
                variant="primary"
                className="ms-3"
                onClick={() => setShow(true)}
              >
                순서 변경
              </Button>
              <RegistBtn />
            </Col>
          </Row>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>필수 여부</th>
              <th>제목</th>
              <th>등록일</th>
              <th>수정일</th>
              <th>순서</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row: any, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`./${row.idx}`}>
                      {data?.number * data.size + index + 1}
                    </Link>
                  </td>
                  <td>{row.required === 1 ? "필수" : "선택"}</td>
                  <td>{row.title}</td>
                  <td>{row.createdAt}</td>
                  <td>{row.updatedAt}</td>
                  <td>{row.sort !== null ? row.sort + 1 : "-"}</td>
                  <td>
                    {row.status === 1 ? (
                      <span className="text-success">활성</span>
                    ) : (
                      <span className="text-danger">비활성</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <TermSort
          title="자주 묻는 질문 순서 변경"
          show={show}
          setShow={setShow}
          search={search}
        />
      </div>
    </MyContainer>
  );
}
