import { Col, Form, Pagination, Row } from "react-bootstrap";
import QueryString from "query-string";
import { setQueryString } from "assets/js/common";

const QUERY_STRING_PAGE_SIZE = "size";
const QUERY_STRING_PAGE_NUMBER = "page";

function MyPagination({ params, search }: any) {
  if (!params) return <></>;

  let number = params.number + 1;
  let totalPages = params.totalPages;
  const size = 5;
  let items = [];
  let start = Math.floor((number - 1) / size) * 5 + 1;
  let end = start + 4 > totalPages ? totalPages : start + 4;
  let queryString = QueryString.parse(window.location.search);

  let defaultSize = (): number => {
    if (typeof queryString.size === "string") return parseInt(queryString.size);
    return 10;
  };

  for (let i = start < 1 ? 1 : start; i <= end; i++) {
    items.push(
      <Pagination.Item
        key={i}
        active={i === number}
        onClick={() => SetPageNum(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  const SetPageNum = (i: number) => {
    setQueryString(QUERY_STRING_PAGE_NUMBER, i.toString());
    search();
  };

  const SetPageSize = (i: number) => {
    setQueryString(QUERY_STRING_PAGE_SIZE, i.toString());
    search();
  };

  const pageHandler = (e: any) => {
    setQueryString(QUERY_STRING_PAGE_NUMBER, "1");
    SetPageSize(e.target.value);
  };

  const startItemNumber = params.number * params.size + 1;
  const endItemNumber = Math.min(
    (params.number + 1) * params.size,
    params.totalElements
  );
  return (
    <Row className=" mt-5">
      <Col xs="auto" className="pt-2">
        Showing {startItemNumber} to {endItemNumber} of {params.totalElements}{" "}
        entries
      </Col>
      <Col>
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => SetPageNum(1)}>
            첫 페이지
          </Pagination.First>
          <Pagination.Prev
            disabled={start === 1}
            onClick={() => SetPageNum(start - 1)}
          >
            이전
          </Pagination.Prev>
          {items}
          <Pagination.Next
            disabled={start + 5 > totalPages}
            onClick={() => SetPageNum(end + 1)}
          >
            다음
          </Pagination.Next>
          <Pagination.Last onClick={() => SetPageNum(totalPages)}>
            마지막 페이지
          </Pagination.Last>
        </Pagination>
      </Col>
      <Col md="auto">
        <Form.Select
          defaultValue={defaultSize()}
          className="d-flex justify-content-end"
          onChange={(e) => pageHandler(e)}
        >
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Form.Select>
      </Col>
    </Row>
  );
}

export default MyPagination;
