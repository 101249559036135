import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { login } from "features/authSlice";
import { Col, Container, Form } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const dispatch = useDispatch();
  const [id, setId] = useState(localStorage.getItem("save_id") || "");
  const [pw, setPw] = useState("");
  const [isSave, setIsSave] = useState(
    localStorage.getItem("save_id") !== null
  );

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (isSave) localStorage.setItem("save_id", id);
    if (event.currentTarget.checkValidity()) onSearchSubmit(id, pw);
    setValidated(true);
    return false;
  };

  const onSearchSubmit = async (id: string, pw: string) => {
    await Api.post("/auth/admin_login", null, { params: { id, pw } })
      .then((response) => {
        dispatch(login(response.data));
      })
      .catch((err) => {
        if (err.request?.status === 404) {
          toast.error("아이디 혹은 비밀번호를 확인하여 주십시오.", {
            autoClose: 1000,
            pauseOnFocusLoss: false,
          });
        } else {
          console.log(err);
        }
      });
  };

  return (
    <Container className="h-100 d-flex align-items-center justify-content-center">
      <Form
        className="align-middle border rounded p-5 bg-muted"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
          <Form.Label>ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="ID"
            defaultValue={id}
            onChange={(e: any) => setId(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            ID를 입력해주세요.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            defaultValue={pw}
            onChange={(e: any) => setPw(e.target.value)}
            required
            minLength={6}
          />
          <Form.Control.Feedback type="invalid">
            비밀번호는 6자리 이상 입력해주세요
          </Form.Control.Feedback>
        </Form.Group>
        <div className="mb-3">
          <label>
            <input
              type="checkbox"
              checked={isSave}
              onChange={(e) => setIsSave(e.target.checked)}
            />
            <span>아이디 저장</span>
          </label>
        </div>

        <Col className="d-flex justify-content-center mb-3">
          <Button type="submit" variant="primary">
            Login
          </Button>
        </Col>
        <Col className="d-flex justify-content-center mb-1">
          <Link to="/find_id">아이디 찾기</Link>
        </Col>
        <Col className="d-flex justify-content-center">
          <Link to="/">비밀번호 찾기</Link>
        </Col>
      </Form>
    </Container>
  );
}

export default Login;
