import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initDefault = JSON.parse(localStorage.getItem("auth") || "{}");
export const auth = createSlice({
  name: "auth",
  initialState: {
    jwt: initDefault?.jwt,
    user: {
      idx: initDefault?.data?.user?.idx,
      id: initDefault?.data?.user?.id,
      role: initDefault?.data?.user?.role,
    },
  },
  reducers: {
    login: (
      state: any,
      { payload }: PayloadAction<{ jwt: string; user: {} }>
    ) => {
      let json = {
        jwt: payload.jwt,
        data: JSON.parse(window.atob(payload.jwt.split(".")[1]).toString()),
      };
      state.jwt = json.jwt;
      state.user = json.data.user;
      localStorage.setItem("auth", JSON.stringify(json));
    },
    logout: (state: any) => {
      localStorage.removeItem("auth");
      state = {};
      window.location.href = "/";
    },
  },
});

export const { login, logout } = auth.actions;

export default auth.reducer;
