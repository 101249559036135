import { Link, useNavigate, useParams } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Api } from "lib/axios/Api";
import { Objection } from "model/Objection";
import { User } from "model/User";
import { Review } from "model/Review";
import {
  appSuccessMsg,
  getReviewImageArray,
  hasReviewImage,
} from "assets/js/common";
import Swal from "sweetalert2";

const ObjectionView = () => {
  const { idx } = useParams();
  const [objection, setObjection] = useState<Objection>();

  useEffect(() => {
    Api.get(`objections/${idx}`).then((response) => {
      setObjection(response.data);
    });
  }, [idx]);

  const convertStatus = (status: number) => {
    switch (status) {
      case 0:
        return "대기";
      case 1:
        return "인정";
      case 2:
        return "무효";
    }
  };

  const convertUserStatusLog = (status: number) => {
    switch (status) {
      case 0:
        return "일반";
      case 1:
        return "블라인드";
      case 2:
        return "경고";
      case 3:
        return "블랙리스트";
    }
    return "";
  };

  const convertUserStatus = (user: User) => {
    switch (user.status) {
      case 0:
        return "일반";
      case 1:
        return `경고(${user.warningCnt}회)`;
      case 2:
        return "블랙리스트";
    }
    return "";
  };
  if (!objection) return <></>;

  return (
    <MyContainer title={"이의신청 상세"}>
      <Row className="flex-column">
        <Col>
          <Row>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>No</Form.Label>
                <Form.Control type="text" defaultValue={idx} readOnly />
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>상태</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={convertStatus(objection.status)}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>처리 상태</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={convertUserStatusLog(
                    objection.userStatusLog.status
                  )}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>처리 사유</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={objection.userStatusLog.reason}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>이메일</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={objection.user.email}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>닉네임</Form.Label>
                <Link
                  to={`/service/users/${objection.userIdx}`}
                  target="_blank"
                >
                  <Form.Control
                    type="text"
                    defaultValue={objection.user.nickName}
                    readOnly
                  />
                </Link>
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>회원 상태</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={convertUserStatus(objection.user)}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>처리 날짜</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={objection.userStatusLog.createdAt}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Form.Group controlId="idx" className="mb-3 col-auto">
                <Form.Label>이의 신청 내용</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={objection.text}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <ReviewContent review={objection.userStatusLog.review} />
        </Col>
        <Col className="d-flex justify-content-end">
          <Buttons objection={objection} />
        </Col>
      </Row>
    </MyContainer>
  );
};
const ReviewContent = ({ review }: { review?: Review }) => {
  if (!review) return <></>;
  return (
    <Row className="flex-column">
      <Col md="8">
        <Form.Group controlId="idx" className="mb-3 col-auto">
          <Form.Label>
            리뷰 내용
            <Link to={`/service/reviews/${review.idx}`}>
              <Button size="sm" className="ms-3">
                {" "}
                리뷰 보기
              </Button>
            </Link>
          </Form.Label>
          <Form.Control
            as="textarea"
            defaultValue={review.reviewDetails[0].text}
            readOnly
            rows={4}
            className="noresize"
          />
        </Form.Group>
      </Col>

      {hasReviewImage(review) && (
        <Col>
          <Row>
            <Form.Label>리뷰 이미지</Form.Label>
            {getReviewImageArray(review).map((imageUrl, i) => (
              <Col className="col-auto" key={i}>
                <Link
                  to={`/image/${encodeURIComponent(imageUrl)}`}
                  target="_blank"
                >
                  <Image src={imageUrl} style={{ maxHeight: "10rem" }} />
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      )}
    </Row>
  );
};

const Buttons = ({ objection }: { objection: Objection }) => {
  const navigate = useNavigate();
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target = e.target as HTMLButtonElement;
    const title = target.dataset.value === "1" ? "인정" : "무효";
    Swal.fire({
      title: `${title} 처리?`,
      showDenyButton: true,
      confirmButtonText: "확인",
      denyButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.put(`objections/${objection.idx}`, {
          ...objection,
          status: target.dataset.value,
        }).then(() => {
          appSuccessMsg({ msg: "처리 완료되었습니다." });
          navigate(-1);
        });
      }
    });
  };
  return (
    <Row>
      {objection.status === 0 && (
        <>
          <Col className="col-auto">
            <Button onClick={clickHandler} data-value="1">
              인정 처리
            </Button>
          </Col>
          <Col className="col-auto">
            <Button onClick={clickHandler} data-value="2">
              무효 처리
            </Button>
          </Col>
        </>
      )}
      <Col className="col-auto">
        <Button variant="secondary" onClick={() => navigate(-1)}>
          취소
        </Button>
      </Col>
    </Row>
  );
};
export default ObjectionView;
