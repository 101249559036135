export const SIDOS = [
  "서울특별시",
  "경상남도",
  "전라남도",
  "세종특별자치시",
  "충청남도",
  "충청북도",
  "강원도",
  "경상북도",
  "경기도",
  "인천광역시",
  "대전광역시",
  "제주특별자치도",
  "부산광역시",
  "전라북도",
  "대구광역시",
  "광주광역시",
  "기타",
  "울산광역시",
  "평안북도",
  "평안남도",
  "황해도",
];

export const CCCE_NAMES = [
  "선사시대",
  "석기시대",
  "청동시대",
  "철기시대",
  "삼한시대",
  "삼국시대",
  "고구려",
  "신라",
  "백제",
  "발해",
  "통일신라",
  "고려시대",
  "조선시대",
  "대한제국시대",
  "일제강점기",
  "시대미상",
];

export const CCMA_NAMES = [
  "국보",
  "보물",
  "사적",
  "명승",
  "천연기념물",
  "국가무형문화재",
  "국가민속문화재",
  "시도유형문화재",
  "시도무형문화재",
  "시도기념물",
  "시도민속문화재",
  "문화재자료",
  "등록문화재",
  "이북5도 무형문화재",
];

export const DECLARATION_STATUS = {
  NOBAL: 0,
  BLIND: 1,
  WARNING: 2,
  BLACKLIST: 3,
  CANCEL: 99,
};
