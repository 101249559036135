import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyContainer from "components/layouts/MyContainer";
import RegistBtn from "components/display/RegistBtn";
import MyPagination from "components/MyPagination";
import { Question, QuestionType, QuestionTypeColumn } from "model/Question";
import { CulturalAsset } from "model/CulturalAsset";
import { commonSearch, deleteQueryString } from "assets/js/common";
import { Api } from "lib/axios/Api";
import SelectFilter from "components/display/SelectFilter";
import InputFilter from "components/display/InputFilter";
import FilterResetBtn from "components/display/FilterResetBtn";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export default function Questions() {
  const [rows, setRows] = useState<Question[]>([]);
  const [data, setData] = useState<any>();
  const [types, setTypes] = useState<QuestionType[]>([]);
  const [columns, setColumns] = useState<QuestionTypeColumn[]>([]);

  const search = commonSearch("/questions", { setData, setRows });
  useEffect(() => {
    commonSearch("/questions", { setData, setRows })();
    Api.get("question_types/all").then((response) => {
      setTypes(response.data);
    });
  }, []);

  const casText = (cas: CulturalAsset[]) => {
    if (!cas.length) return "-";
    if (cas.length === 1) return cas[0].ccbaMnm1 ?? "-";
    return `${cas[0].ccbaMnm1} 외 ${cas.length - 1}`;
  };

  const typeChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const filter = types.filter(
      (type) => type.idx === parseInt(e.target.value)
    );
    deleteQueryString("questionTypeColumnIdx");
    if (filter.length > 0 && filter[0].columns) setColumns(filter[0].columns);
    else setColumns([]);
    search();
  };

  const excelUploadClickHandler = async (e: any) => {
    const { value: file } = await Swal.fire({
      title: "파일을 선택해주세요",
      input: "file",
      inputAttributes: {
        accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    });

    if (file) {
      const formData = new FormData();
      formData.append("upload", file);
      Api.post("/questions/upload/excel", formData)
        .then((res) => {
          toast.success("저장되었습니다.", {
            autoClose: 500,
            pauseOnFocusLoss: false,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data.code === "E001") {
            if (e.response.data.body !== null) {
              Swal.fire({
                icon: "error",
                html: Object.entries(e.response.data.body)
                  .map((m) => m.join(": "))
                  .join("<br/>"),
              });
            } else {
              Swal.fire({
                icon: "error",
                text: e.response.data.message,
              });
            }
          }
        });
    }
  };

  return (
    <MyContainer title="기출 문제 관리">
      <div>
        <div>
          <Row className="mb-3">
            <SelectFilter search={typeChangeHandler} name="questionTypeIdx">
              <option value="">기출 종류</option>
              {types.map((type) => (
                <option value={type.idx} key={type.idx}>
                  {type.name}
                </option>
              ))}
            </SelectFilter>
            <SelectFilter search={search} name="questionTypeColumnIdx">
              <option value="">회차</option>
              {columns.map((column) => (
                <option value={column.idx} key={column.idx}>
                  {column.cnt}
                </option>
              ))}
            </SelectFilter>
            <FilterResetBtn search={search} />
          </Row>
          <InputFilter search={search}>
            <option value="createdAt">출제일</option>
            <option value="culName">연관문화재</option>
          </InputFilter>
        </div>
        <Row>
          <Col className="d-flex align-items-end justify-content-end mb-3">
            <Button onClick={excelUploadClickHandler}>엑셀 등록</Button>
            <RegistBtn />
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>종류</th>
              <th>연관 문화재</th>
              <th>출제일</th>
              <th>회차</th>
              <th>LEVEL</th>
              <th>문제번호</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td key={row.idx}>
                  <Link to={`./${row.idx}`}>
                    {data?.number * data.size + index + 1}
                  </Link>
                </td>
                <td>{row.questionType?.name ?? "-"}</td>
                <td className="text-truncate" style={{ maxWidth: "20rem" }}>
                  {casText(row.culs ?? [])}
                </td>
                <td>{row.questionTypeColumn?.date}</td>
                <td>{row.questionTypeColumn?.cnt}</td>
                <td>{row.questionTypeLevel?.text ?? "-"}</td>
                <td>{String(row.number)}</td>
                <td>{row.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <MyPagination params={data} search={search} />
      </div>
    </MyContainer>
  );
}
