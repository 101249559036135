import React from "react";
import { ListGroup } from "react-bootstrap";

interface SortListProps {
  list: any[];
  setList: Function;
  key?: string;
  children: React.ReactElement[];
}
const SortList = ({ list, setList, key = "idx", children }: SortListProps) => {
  let target: any[];
  const listItemFilter = (tKey: string) =>
    list.filter((item) =>
      typeof item[key] === "number"
        ? item[key] === parseInt(tKey)
        : item[key] === tKey
    )[0];

  const onDropHandler = (e: React.DragEvent<HTMLElement>) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const type = e.clientY > rect.top + rect.height / 2 ? "down" : "up";

    const afterTarget = listItemFilter(
      (e.target as HTMLElement).dataset.key as string
    );
    const newList = [...list];

    newList.splice(list.indexOf(target), 1);
    newList.splice(
      newList.indexOf(afterTarget) + (type === "up" ? 0 : 1),
      0,
      target
    );

    setList(newList);
  };

  const onDragStartHandler = (e: React.DragEvent<HTMLElement>) => {
    target = listItemFilter((e.target as HTMLElement).dataset.key as string);
  };

  return (
    <>
      <ListGroup className="sorted mb-3">
        {children.map((child, i) =>
          React.cloneElement(child, {
            onDrop: onDropHandler,
            onDragStart: onDragStartHandler,
            onDragOver: (e: any) => {
              e.preventDefault();
            },
            "data-key": child.key,
            key: i,
          })
        )}
      </ListGroup>
    </>
  );
};

export default SortList;
