import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Api } from "lib/axios/Api";
import { useNavigate, useParams } from "react-router-dom";
import { QuestionType, QuestionTypeLevel } from "model/Question";
import MyContainer from "components/layouts/MyContainer";
import {
  DateToYmd,
  appErrorMsg,
  commonSave,
  commonSwalConfirm,
} from "assets/js/common";
import QuestionTypeLevelComponet from "./QuestionTypeLevelComponet";
import QuestionTypeItemComponent from "./QuestionTypeItemComponent";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function QuestionTypeView() {
  const navigate = useNavigate();
  const params = useParams();
  const { idx } = params as { idx: string };
  const [validated, setValidated] = useState(false);
  const [questionType, setQuestionType] = useState<QuestionType>();
  const [questionTypeLevels, setQuestionTypeLevels] = useState<
    QuestionTypeLevel[]
  >([]);

  useEffect(() => {
    Api.get(`/question_types/${idx}`).then((response) => {
      setQuestionType(response.data);
    });
  }, [idx]);

  useEffect(() => {
    if (questionType && questionType.idx) {
      Api.get(`question_type_level/${questionType.idx}`).then((response) => {
        setQuestionTypeLevels(response.data);
      });
    }
  }, [questionType]);

  const addHandler = async () => {
    if (!questionType) return;

    const MySwal = withReactContent(Swal);
    MySwal.fire({
      html: <></>,
      confirmButtonText: "확인",
      width: window.screen.width < 800 ? "100%" : 800,
    });

    const inputDate = async () => {
      return await Swal.fire({
        title: "출제일",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          placeholder: DateToYmd(new Date()),
        },
      }).then(async (result) => {
        const value = result.value;
        if (!/\d{4}-\d{2}-\d{2}/.test(value) || !Date.parse(value)) {
          return appErrorMsg({ msg: "올바른 날짜를 입력하여 주십시오" });
        }

        return value;
      });
    };

    const inputCnt = async () => {
      return await Swal.fire({
        title: "회차",
        input: "number",
        inputAttributes: {
          autocapitalize: "off",
        },
      }).then(async (result) => {
        return result.value;
      });
    };

    const newDate = await inputDate();
    if (!newDate) return;

    const newCnt = await inputCnt();
    if (!newCnt) return;

    const result = await commonSwalConfirm({
      title: "추가하시겠습니까?",
      confirmButtonText: "추가",
      text: `출제일: ${newDate}, 회차: ${newCnt}`,
    });

    if (result.isConfirmed) {
      Api.post("question_type_columns", {
        typeIdx: questionType.idx,
        date: newDate,
        cnt: newCnt,
      }).then((response) => {
        updateModel({ columns: [...questionType.columns, response.data] });
      });
    }
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.checkValidity()) {
      commonSave({ send });
    }
    setValidated(true);
  };

  const send: any = () => {
    return Api.put(`/question_types/${idx}`, questionType).then((response) => {
      setQuestionType(response.data);
    });
  };

  const updateModel = (fieldsToUpdate: Partial<QuestionType>) => {
    if (questionType) setQuestionType({ ...questionType, ...fieldsToUpdate });
  };

  const updateStatus = (status: number) => {
    commonSave({
      send: () => {
        return Api.put(`/question_types/status/${idx}`, { status }).then(() => {
          updateModel({ status });
        });
      },
      title: "상태를 변경하시겠습니까?",
    });
  };

  if (!questionType) return <></>;
  return (
    <MyContainer title={"기출 출제일 상세"}>
      <Row>
        <Form validated={validated} onSubmit={submitHandler}>
          <Row className="mb-3" xs="3">
            <Form.Control
              type="hidden"
              name="idx"
              value={questionType?.idx ?? ""}
            />
            <Form.Group as={Col} className="mb-3">
              <Form.Label>기출시험 종류</Form.Label>
              <Form.Control
                type="text"
                placeholder="직접입력"
                defaultValue={questionType.name}
                onChange={(e) => updateModel({ name: e.target.value })}
                required
                maxLength={21}
              />
            </Form.Group>
          </Row>
          <QuestionTypeLevelComponet
            questionType={questionType as QuestionType}
            questionTypeLevels={questionTypeLevels}
            setQuestionTypeLevels={setQuestionTypeLevels}
          />

          <Row className="mb-3">
            <Col md="auto" className="me-3">
              출제 일
            </Col>
            <Col>
              {questionType.columns
                .sort((a, b) => (a.cnt < b.cnt ? 1 : -1))
                .map((column: any, n) => (
                  <QuestionTypeItemComponent
                    column={column}
                    questionType={questionType}
                    updateModel={updateModel}
                    key={n}
                  />
                ))}
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="primary" className="ms-3" onClick={addHandler}>
                출제일 회차 추가
              </Button>
            </Col>
          </Row>

          <Col className="d-flex justify-content-end">
            {questionType.status === 0 ? (
              <Button
                variant="success"
                className="ms-3"
                onClick={() => updateStatus(1)}
              >
                활성화
              </Button>
            ) : (
              <Button
                variant="danger"
                className="ms-3"
                onClick={() => updateStatus(0)}
              >
                비활성화
              </Button>
            )}
            <Button
              variant="danger"
              className="ms-3"
              onClick={() => navigate(-1)}
            >
              취소
            </Button>
            <Button type="submit" variant="primary" className="ms-3">
              저장
            </Button>
          </Col>
        </Form>
      </Row>
    </MyContainer>
  );
}
