import MyContainer from "components/layouts/MyContainer";
import { Api } from "lib/axios/Api";
import { Inquiry, InquiryColumn } from "model/types";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormLabel,
  Image,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store/store";
import Swal from "sweetalert2";

export default function InquiryView() {
  const navigate = useNavigate();
  const params = useParams();
  const { idx } = params;
  const [inquiry, setInquiry] = useState<Partial<Inquiry>>({});
  const auth = useSelector((state: RootState) => state.auth);
  const [inquiryColumn, setInquiryColumn] = useState<Partial<InquiryColumn>>({
    adminIdx: auth.user.idx,
    type: 1,
  });

  useEffect(() => {
    Api.get(`/inquiries/${idx}`).then((response) => {
      console.log(response.data);
      setInquiry(response.data);
    });
  }, [idx]);

  const submitHandler = () => {
    Swal.fire({
      title: "답변을 등록하시겠습니까?",
      showDenyButton: true,
      confirmButtonText: "저장",
      denyButtonText: "취소",
    }).then((result) => {
      if (!result.isConfirmed) return;

      const columns: InquiryColumn[] =
        inquiry.inquiryColumns as InquiryColumn[];
      columns.push(inquiryColumn as InquiryColumn);
      const params: Inquiry = {
        ...(inquiry as Inquiry),
        inquiryColumns: columns,
      };
      Api.put(`/inquiries/${idx}`, { ...params }).then(() =>
        window.location.reload()
      );
    });
  };

  const updateModel = (fieldsToUpdate: Partial<InquiryColumn>) => {
    if (inquiryColumn)
      setInquiryColumn({ ...inquiryColumn, ...fieldsToUpdate });
  };

  const convertStatus = (status: number | undefined) => {
    switch (status) {
      case 0:
        return "일반";
      case 1:
        return "경고";
      case 2:
        return "블랙리스트";
      default:
        return "탈퇴";
    }
  };
  if (inquiry)
    return (
      <MyContainer title="문의 상세">
        <Form>
          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              상태
            </Form.Label>
            <Form.Label column>
              {inquiry.status === 1 ? "답변완료" : "답변대기"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              문의 유형
            </Form.Label>
            <Form.Label column>{inquiry.type}</Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              닉네임
            </Form.Label>
            <Form.Label column>{inquiry.user?.nickName}</Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              이메일
            </Form.Label>
            <Form.Label column>{inquiry.user?.email}</Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              회원 상태
            </Form.Label>
            <Form.Label column>
              {convertStatus(inquiry.user?.status)}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              등록일
            </Form.Label>
            <Form.Label column>{inquiry.createdAt}</Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              이미지
            </Form.Label>
            {inquiry.imageUrl != null && (
              <Col className="col-auto">
                <Image src={inquiry.imageUrl} style={{ maxHeight: "10rem" }} />
              </Col>
            )}
          </Form.Group>

          <Form.Group as={Row} className="mb-3 border">
            <Form.Label column sm={2}>
              제목
            </Form.Label>
            <Form.Label column>{inquiry.title}</Form.Label>
          </Form.Group>

          <hr />
          {inquiry.inquiryColumns &&
            inquiry.inquiryColumns.map((column) => (
              <Column column={column} key={column.idx} />
            ))}

          <Row className="my-5">
            <FloatingLabel
              controlId="floatingTextarea2"
              label="답변"
              className="p-0"
            >
              <Form.Control
                as="textarea"
                placeholder="답변"
                style={{ height: "100px" }}
                onChange={(e) => updateModel({ text: e.target.value })}
              />
            </FloatingLabel>
          </Row>

          <Col className="d-flex justify-content-end">
            <Button
              variant="danger"
              className="ms-3"
              onClick={() => navigate(-1)}
            >
              취소
            </Button>
            <Button variant="primary" className="ms-3" onClick={submitHandler}>
              확인
            </Button>
          </Col>
        </Form>
      </MyContainer>
    );
  else return <></>;
}

function Column(props: { column: InquiryColumn }) {
  const { column } = props;
  if (column.type === 0)
    return (
      <Row className="my-4">
        <Col
          md="auto"
          className="border rounded border-primary p-4"
          style={{
            position: "relative",
            minWidth: "20rem",
            maxWidth: "60vw",
          }}
        >
          <FormLabel>{column.text}</FormLabel>
          <FormLabel
            className="bg-white px-2"
            style={{
              position: "absolute",
              bottom: "-1rem",
              right: "1rem",
              whiteSpace: "nowrap",
            }}
          >
            {column.createdAt}
          </FormLabel>
        </Col>
      </Row>
    );
  else
    return (
      <Row className="my-4 justify-content-md-end">
        <Col
          md="auto"
          className="border rounded border-warning p-4"
          style={{
            position: "relative",
            minWidth: "20rem",
            maxWidth: "60vw",
          }}
        >
          <FormLabel>{column.text}</FormLabel>
          <FormLabel
            className="bg-white px-2"
            style={{
              position: "absolute",
              bottom: "-1rem",
              right: "1rem",
              whiteSpace: "nowrap",
            }}
          >
            {column.admin?.name}&nbsp;&nbsp;{column.createdAt}
          </FormLabel>
        </Col>
      </Row>
    );
}
