import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function RegistBtn() {
  return (
    <Link to="./regist">
      <Button variant="primary" className="ms-3">
        등록
      </Button>
    </Link>
  );
}
